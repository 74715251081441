import Button from 'src/common/components/Button';
import Flex from 'src/common/components/Flex';
import { AddSmall10, EntityGroup } from 'src/common/components/Icons';
import Typography from 'src/common/components/Typography';

export function CreateNewEntityContent({ onCreateNewOpen }: { onCreateNewOpen: VoidFunction }) {
	return (
		<Flex justifyContent={'center'} width={'100%'}>
			<Flex
				maxWidth={'400px'}
				flexDirection={'column'}
				alignContent={'center'}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<EntityGroup />
				<Typography marginTop={'6px'} marginBottom={'8px'} variant="Paragraph20S" color="gray.1000">
					Start with Your First Entity.
				</Typography>
				<Typography textAlign={'center'} marginBottom={'24px'} variant="Paragraph16R" color="gray.1000">
					Create your first entity and add relationships and dimensions to enhance your data.
				</Typography>
				<Button
					leftIcon={<AddSmall10 />}
					variant="solid"
					onClick={onCreateNewOpen}
					size="small"
					colorScheme="blue"
					borderRadius={'8px'}
				>
					Create Entity
				</Button>
			</Flex>
		</Flex>
	);
}
