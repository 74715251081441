import Box from '@components/Box';
import Button from '@components/Button';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import Popover from '@components/Popover';
import { useEffect, useState } from 'react';
import shadows from 'src/style/shadows';
import Input from '../Input';
import Select from '../Select';
import { DateUnit, dateUnitSelectOptions, RelativeDatePickerPopoverProps } from './types';

export const RelativeDatePickerPopover = ({
	isOpen = true,
	onCancel,
	onApply,
	triggerElement,
	initialValue,
	initialUnit,
}: RelativeDatePickerPopoverProps) => {
	const [unit, setUnit] = useState<DateUnit>(initialUnit);
	const [value, setValue] = useState<number>(initialValue);

	useEffect(() => {
		setUnit(initialUnit);
		setValue(initialValue);
	}, [initialUnit, initialValue, isOpen]);

	return (
		<Popover
			autoFocus={true}
			isOpen={isOpen}
			placement={'bottom-start'}
			onClose={onCancel}
			popoverContentProps={{
				borderRadius: '8px',
				boxShadow: shadows['a-22'],
			}}
			triggerElement={triggerElement}
		>
			<Box w={'320px'}>
				<Flex padding={'16px'} gap="12px">
					<Flex flex={1}>
						<Input
							type="number"
							autoFocus
							value={value}
							onChange={(v) => {
								setValue(Number(v));
							}}
							size="md"
						/>
					</Flex>
					<Flex flex={1}>
						<Select<DateUnit>
							options={dateUnitSelectOptions}
							size={'medium'}
							borderRadius={'8px'}
							width={'100%'}
							selectedOption={dateUnitSelectOptions.find((o) => o.value === unit)}
							onChange={(option) => {
								setUnit(option.value);
							}}
						/>
					</Flex>
				</Flex>
				<Divider direction="horizontal" color={'gray.300'} />
				<Flex justifyContent="flex-end" alignItems={'center'} gap={'12px'} padding={'12px 16px'}>
					<Button onClick={onCancel} colorScheme="gray" size="small" variant="outline">
						Cancel
					</Button>
					<Button
						onClick={() => {
							onApply({ unit, value });
						}}
						colorScheme="blue"
						size="small"
						variant="solid"
					>
						Apply
					</Button>
				</Flex>
			</Box>
		</Popover>
	);
};
