import Box from '@components/Box';
import Flex from '@components/Flex';
import { useLocation } from 'react-router-dom';
import useMutation from '@hooks/fetching/useMutation';
import Button from 'src/common/components/Button';
import IconButton from 'src/common/components/IconButton';
import { CloseLarge16, DeleteForever92 } from 'src/common/components/Icons';
import Modal from 'src/common/components/Modal';
import Typography from 'src/common/components/Typography';
import { DeleteCollectionMutation, DeleteCollectionMutationVariables } from 'src/generated/graphql';
import { DeleteCollection } from 'src/queries/collections';
import useNavigation from 'src/services/useNavigation';
import { Collection } from './types';
import { DashboardPath } from 'src/pages/DashboardPage';
import { WorkspacePath } from 'src/pages/WorkspacePage';
import { TestIDs } from 'src/common/types/test-ids';
import { useCallback } from 'react';
import { useReportEvent } from 'src/services/analytics';

type DeleteCollectionModalProps = {
	isOpen: boolean;
	onClose: () => void;
	collection: Collection;
	isCollectionInView: boolean;
};

function DeleteCollectionModal({ isOpen, onClose, collection, isCollectionInView }: DeleteCollectionModalProps) {
	const [deleteCollection, { loading: isLoading }] = useMutation<
		DeleteCollectionMutation,
		DeleteCollectionMutationVariables
	>(DeleteCollection);
	const { navigate } = useNavigation();
	const location = useLocation();
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });

	const onDelete = useCallback(async () => {
		if (collection.collection_type === 'dashboard') {
			reportEvent({
				event: 'sidebar-delete-item',
				metaData: { itemType: collection.collection_type, name: collection.name },
			});
		}

		await deleteCollection({
			variables: {
				id: collection.id,
				name: `${collection.name}  (${new Date().toISOString()})`,
			},
		});
		onClose();

		if (location.pathname.includes(collection.id)) {
			navigate({ path: collection.collection_type === 'dashboard' ? DashboardPath : WorkspacePath });
			return;
		}

		if (isCollectionInView) {
			navigate({ path: 'feed' });
		}
	}, [
		collection.collection_type,
		collection.id,
		collection.name,
		deleteCollection,
		isCollectionInView,
		location.pathname,
		navigate,
		onClose,
	]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} minHeight={'376px'} maxWidth="500px">
			<Flex
				direction="column"
				paddingX={'32px'}
				paddingBottom="32px"
				paddingTop={'48px'}
				justifyContent="center"
				alignItems="center"
				width={'100%'}
				height={'100%'}
			>
				<Box position={'absolute'} top="16px" left="16px">
					<IconButton
						icon={
							<Box color="gray.800">
								<CloseLarge16 width="16px" height="16px" />
							</Box>
						}
						onClick={onClose}
					/>
				</Box>

				<DeleteForever92 />
				<Flex
					marginTop={'24px'}
					direction="column"
					gap={'16px'}
					alignItems="center"
					textAlign={'center'}
					paddingX="20px"
				>
					<Typography variant="DesktopH5" color="gray.800">
						Are you sure?
					</Typography>
					<Typography variant="Paragraph14R">
						Once you delete this {collection.collection_type}, nobody can access it, and there&#39;s no turning back.
					</Typography>
				</Flex>
				<Flex direction={'row'} gap="16px" marginTop={'32px'}>
					<Button onClick={onClose} variant="ghost" colorScheme="gray" eventName="collection-delete-canceled">
						Never mind
					</Button>
					<Button
						onClick={onDelete}
						variant="solid"
						colorScheme="gray"
						isLoading={isLoading}
						eventName="collection-delete-confirmed"
						data-testid={TestIDs.CONFIRM_COLLECTION_DELETE_BUTTON(collection.collection_type)}
					>
						Delete
					</Button>
				</Flex>
			</Flex>
		</Modal>
	);
}

export default DeleteCollectionModal;
