import { collectionsNavigationDrawer } from 'src/stores/utils';
import { useCollectionsSubscriptions } from '@hooks/fetching/useCollectionsSubscriptions';
import Box from '@components/Box';
import classes from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesNavigationDrawer.module.scss';
import Flex from '@components/Flex';
import classNames from 'classnames';
import { TestIDs } from 'src/common/types/test-ids';
import { SpaceListItem } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesListDrawer/SpaceListItem';
import Scrollable from '../../../Scrollable';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { DraggableFolders } from '../../DashboardFolder';
import { isDashboardsPage } from 'src/pages/WorkspacePage/utils';

export const SpacesListDrawer = () => {
	const currentDrawerView = isDashboardsPage()
		? collectionsNavigationDrawer.dashboard
		: collectionsNavigationDrawer.workspace;

	const { rawDashboardsList, rawWorkspacesList } = useCollectionsSubscriptions();

	const rawCollectionsListItems =
		currentDrawerView == collectionsNavigationDrawer.dashboard ? rawDashboardsList : rawWorkspacesList;

	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');
	const isDashboardFoldersListEnable = isDashboardFoldersEnable && isDashboardsPage();

	return (
		<Box className={classNames(classes.itemsList)}>
			<Flex
				data-testid={currentDrawerView}
				h="100%"
				justifyContent="flex-start"
				flexDir="column"
				overflow="hidden"
				zIndex="1"
			>
				{/* <Box overflowX="hidden" overflowY="auto" data-testid={TestIDs.SIDEBAR_ITEMS(currentDrawerView)}> */}
				<Scrollable data-testid={TestIDs.SIDEBAR_ITEMS(currentDrawerView)}>
					{isDashboardFoldersListEnable ? (
						<DraggableFolders />
					) : (
						rawCollectionsListItems?.workspaces?.map((collection, index) => (
							<SpaceListItem
								key={collection.id}
								currentDrawerView={currentDrawerView}
								collection={collection}
								index={index}
								testId={TestIDs.SIDEBAR_ITEM(currentDrawerView)}
							/>
						))
					)}
				</Scrollable>
				{/* </Box> */}
			</Flex>
		</Box>
	);
};
