import { gql } from '@apollo/client';
import { WorkspaceFragment } from './workspaces';

export const InsertFolder = gql`
	mutation InsertFolder($name: String!, $emoji: String!) {
		insert_folders_one(object: { name: $name, emoji: $emoji }) {
			id
		}
	}
`;

export const ListAllFolders = gql`
	${WorkspaceFragment}
	query ListAllFolders($my_id: uuid) {
		folders {
			id
			name
			emoji
			order
			dashboards {
				...WorkspaceFragment
			}
		}
	}
`;

export const GetAllFolders = gql`
	${WorkspaceFragment}
	query GetAllFolders($my_id: uuid) {
		folders {
			id
			name
			emoji
			order
			dashboards {
				...WorkspaceFragment
			}
		}
	}
`;

export const GetDashboardsFromSingleFolder = gql`
	${WorkspaceFragment}
	query GetDashboardsFromSingleFolder($offset: Int!, $my_id: uuid) {
		folders(limit: 1, offset: $offset, order_by: { order: asc }) {
			id
			dashboards {
				...WorkspaceFragment
			}
		}
	}
`;

export const DeleteFolder = gql`
	mutation DeleteFolder($id: uuid!) {
		delete_folders(where: { id: { _eq: $id } }) {
			returning {
				id
				name
			}
		}
	}
`;

export const EditFolder = gql`
	mutation EditFolder($id: uuid!, $name: String!, $emoji: String!) {
		update_folders(where: { id: { _eq: $id } }, _set: { name: $name, emoji: $emoji }) {
			returning {
				id
				name
				emoji
			}
		}
	}
`;

export const ReorderDashboard = gql`
	mutation ReorderDashboard($dashboard_id: uuid!, $new_folder_id: uuid!, $new_order: bigint!) {
		update_workspaces(where: { id: { _eq: $dashboard_id } }, _set: { folder_id: $new_folder_id, order: $new_order }) {
			returning {
				id
				folder_id
				order
			}
		}
	}
`;

export const ReorderFolder = gql`
	mutation ReorderFolder($id: uuid!, $new_order: bigint!) {
		update_folders(where: { id: { _eq: $id } }, _set: { order: $new_order }) {
			returning {
				id
				order
			}
		}
	}
`;
