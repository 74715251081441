import Box from '@components/Box';
import Button from '@components/Button';
import { DatePickerField } from '@components/DatePickerPopover/DatePickerField';
import { PeriodPickerField } from '@components/DatePickerPopover/PeriodPickerField';
import Divider from '@components/Divider';
import Flex from '@components/Flex';
import Popover from '@components/Popover';
import { useState } from 'react';
import shadows from 'src/style/shadows';
import './DatePickerPopover.scss';

type DatePickerPopoverContentProps = {
	isOpen: boolean;
	fromDate: Date | null;
	toDate?: Date | null;
	isPeriodPicker?: boolean;
	onCancel: VoidFunction;
	onApply: ({ fromDate, toDate }: { fromDate: Date | null; toDate: Date | null }) => void;
	triggerElement?: React.ReactNode;
	label?: string;
};

export const DatePickerPopover = ({
	isOpen = true,
	fromDate,
	toDate,
	isPeriodPicker,
	onCancel,
	onApply,
	triggerElement,
	label,
}: DatePickerPopoverContentProps) => {
	const [fromDateValue, setFromDateValue] = useState<Date | null>(fromDate || null);
	const [toDateValue, setToDateValue] = useState<Date | null>(toDate || null);

	const onDateChange = (value: string | null) => {
		setFromDateValue(value ? new Date(value) : null);
	};

	const onDateRangeChange = ([fromDate, toDate]: [Date, Date]) => {
		setFromDateValue(fromDate);
		setToDateValue(toDate);
	};

	const onClose = () => {
		setFromDateValue(null);
		setToDateValue(null);
	};

	return (
		<Popover
			autoFocus={true}
			isOpen={isOpen}
			placement={'bottom-start'}
			onClose={onCancel}
			popoverContentProps={{
				borderRadius: '8px',
				boxShadow: shadows['a-22'],
			}}
			triggerElement={triggerElement}
		>
			<Box w={'320px'} id={'picker-container'}>
				<Box padding={'16px'}>
					{isPeriodPicker ? (
						<PeriodPickerField
							onDateRangeChange={onDateRangeChange}
							fromDateValue={fromDateValue}
							toDateValue={toDateValue}
						/>
					) : (
						<DatePickerField onDateChange={onDateChange} fromDateValue={fromDateValue} label={label} />
					)}
				</Box>
				<Divider direction="horizontal" color={'gray.300'} />
				<Flex justifyContent="flex-end" alignItems={'center'} gap={'12px'} padding={'12px 16px'}>
					<Button onClick={onCancel} colorScheme="gray" size="small" variant="outline">
						Cancel
					</Button>
					<Button
						onClick={() => {
							onApply({ fromDate: fromDateValue, toDate: toDateValue });
							onClose();
						}}
						colorScheme="blue"
						size="small"
						variant="solid"
						isDisabled={!fromDateValue || (isPeriodPicker && !toDateValue)}
					>
						Apply
					</Button>
				</Flex>
			</Box>
		</Popover>
	);
};
