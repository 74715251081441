import { Box } from '@chakra-ui/react';
import Center from '@components/Center';
import Typography from '@components/Typography';
import Select from 'src/common/components/Select';
import { CloudSupportedOperators, SupportedDimensionTypes } from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import { getOperatorDisplayName } from './constants';

export const Operator = ({ operator }: { operator: string }) => {
	return (
		<Center padding={'6px 8px'} cursor={'default'}>
			<Typography variant={'DesktopH10Medium'} color={colors.gray[1000]}>
				{operator}
			</Typography>
		</Center>
	);
};

export const OperatorSelect = ({
	operator,
	availableOperators,
	type,
	dimension,
	onSelectOperator,
	onOpen,
	isOpen,
	onClose,
}: {
	operator: CloudSupportedOperators;
	availableOperators: CloudSupportedOperators[];
	type: SupportedDimensionTypes;
	dimension?: string;
	onSelectOperator: (operator: CloudSupportedOperators) => void;
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}) => {
	const { reportEvent } = useReportEvent();
	if (availableOperators.length === 1) return <Operator operator={getOperatorDisplayName(operator, type)} />;
	return (
		<div data-intercom-target="filter-operator" data-intercom-area={'metric'} data-intercom-type={'main'}>
			<Box
				paddingLeft={'8px'}
				paddingBottom={'6px'}
				paddingTop={'2px'}
				borderRadius={'8px'}
				_hover={{ backgroundColor: colors.blue[100] }}
				backgroundColor={isOpen ? colors.gray[200] : colors.transparent}
			>
				<Select<CloudSupportedOperators>
					options={availableOperators.map((option) => ({
						label: getOperatorDisplayName(option, type),
						value: option,
					}))}
					size={'button'}
					selectedOption={{
						label: getOperatorDisplayName(operator, type),
						value: operator,
					}}
					onMenuOpen={() => {
						onOpen();
						reportEvent({
							event: 'filters-edit-operator',
							metaData: {
								feature: 'filters',
								application: 'metric investigation',
								operator: getOperatorDisplayName(operator, type),
								'dimension-type': type,
								dimension,
							},
						});
					}}
					onMenuClose={onClose}
					menuIsOpen={isOpen}
					onChange={(selectedOption) => {
						reportEvent({
							event: 'filters-operator-selected',
							metaData: {
								feature: 'filters',
								application: 'metric investigation',
								operator: getOperatorDisplayName(selectedOption.value, type),
								'dimension-type': type,
								dimension,
							},
						});
						onSelectOperator(selectedOption.value);
					}}
					color={colors.gray[1000]}
					isGhost={true}
				/>
			</Box>
		</div>
	);
};
