import useSubscription from '@hooks/fetching/useSubscription';
import { GetCollectionsSubscription, GetCollectionsSubscriptionVariables } from 'src/generated/graphql';
import { GetCollections, GetFolderCollections } from 'src/queries/collections';
import { collectionsNavigationDrawer } from 'src/stores/utils';
import useFeatureFlag from '../stores/useFeatureFlag';

export function useCollectionsSubscriptions() {
	const isDashboardFoldersEnable = useFeatureFlag('pulse.sightfull2.dashboard.folders.enable');

	const { data: rawDashboardsList } = useSubscription<GetCollectionsSubscription, GetCollectionsSubscriptionVariables>(
		isDashboardFoldersEnable ? GetFolderCollections : GetCollections,
		{
			variables: {
				collection_type: collectionsNavigationDrawer.dashboard.toLowerCase(),
			},
		}
	);

	const { data: rawWorkspacesList } = useSubscription<GetCollectionsSubscription, GetCollectionsSubscriptionVariables>(
		GetCollections,
		{
			variables: {
				collection_type: collectionsNavigationDrawer.workspace.toLowerCase(),
			},
		}
	);

	return { rawDashboardsList, rawWorkspacesList };
}
