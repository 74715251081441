import { useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import Box from '@components/Box';

import useUser from 'src/common/hooks/stores/useUser';
import { DashboardFolder } from './index';
import _ from 'lodash';
import { SkeletonFolder } from './Skeletons/SkeletonFolder';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { useReportEvent } from 'src/services/analytics';
import './DraggableFolders.scss';
import { ListAllFolders } from 'src/queries/folders';
import { useHasuraSubscriptionWithCache } from 'src/common/hooks/fetching/useSubscription';
import { GetAllFoldersQuery } from 'src/generated/graphql';
import { FolderData } from './types';

export const DraggableFolders = () => {
	const [{ id: my_id }] = useUser();
	const { data: foldersList, loading: isFoldersLoading } = useHasuraSubscriptionWithCache(ListAllFolders, {
		variables: { my_id: my_id },
	});

	const { reorderDashboardFolder, reorderFolderDashboard } = useDashboardFolders();

	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });

	const sortedFolders: FolderData[] = useMemo(
		() =>
			_.sortBy(foldersList?.folders, 'order').map((folder) => ({
				id: folder.id,
				folderName: folder.name,
				emoji: folder.emoji,
				dashboards: folder.dashboards,
				order: folder.order,
			})),
		[foldersList?.folders]
	);

	const sortedDashboardNodes = useMemo(
		() => sortedFolders.map((folder) => folder.dashboards).map((el) => _.sortBy(el, 'order')),
		[sortedFolders]
	);

	const getOrder = (items: FolderData[] | GetAllFoldersQuery['folders'][number]['dashboards'], itemId: string) => {
		const index = items.findIndex((item) => item.id === itemId);
		if (index === -1) return;
		if (index === 0) {
			const order = items[1].order;
			if (!order) return;
			return order / 2;
		}
		if (index === items.length - 1) return Date.now() * 1000;
		const prevOrder = items[index - 1].order;
		const nextOrder = items[index + 1].order;
		if (!prevOrder || !nextOrder) return;
		return (prevOrder + nextOrder) / 2;
	};

	const reorderFolders = useCallback(
		async (items: FolderData[], folderId: string) => {
			reportEvent({ event: 'sidebar-reorder-item', metaData: { itemType: 'folder' } });
			const newOrder = getOrder(items, folderId);
			if (newOrder) await reorderDashboardFolder(folderId, newOrder);
		},
		[reorderDashboardFolder, reportEvent]
	);

	const reorderDashboards = useCallback(
		async (items: GetAllFoldersQuery['folders'][number]['dashboards'], dashboarId: string, folderId?: string) => {
			if (!items || !dashboarId || !folderId) return;
			reportEvent({ event: 'sidebar-reorder-item', metaData: { itemType: 'dashboard' } });
			const newOrder = getOrder(items, dashboarId);
			if (newOrder) await reorderFolderDashboard(dashboarId, folderId, newOrder);
		},
		[reorderFolderDashboard, reportEvent]
	);

	const onDragEnd = useCallback(
		async (result: DropResult) => {
			if (!result.destination) return;
			if (result.type === 'folders') {
				const items = [...sortedFolders];
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);
				await reorderFolders(items, result.draggableId);
			} else if (result.type.includes('dashboards-subItem')) {
				const foundDashboardNode = sortedDashboardNodes?.find((dashboardNode) =>
					dashboardNode?.some((dashboard) => dashboard.id === result.draggableId)
				);
				const items = foundDashboardNode ? [...foundDashboardNode] : [];
				const [reorderedItem] = items.splice(result.source.index, 1);
				items.splice(result.destination.index, 0, reorderedItem);
				const foundDashboardNodeIndex = sortedDashboardNodes?.findIndex((dashboardNode) =>
					dashboardNode?.some((dashboard) => dashboard.id === result.draggableId)
				);
				if (foundDashboardNodeIndex !== undefined && foundDashboardNodeIndex !== -1) {
					const updatedDashboardNodes = [...sortedDashboardNodes];
					updatedDashboardNodes[foundDashboardNodeIndex] = items;
					const folder = sortedFolders.find((folder) =>
						folder?.dashboards?.some((dashboard) => dashboard.id === result.draggableId)
					);
					await reorderDashboards(items, result.draggableId, folder?.id);
				}
			}
		},
		[sortedDashboardNodes, sortedFolders, reorderDashboards, reorderFolders]
	);

	const droppableContent = (
		<DragDropContext autoScrollerOptions={{ disabled: true }} onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable-folders" type="folders">
				{(provided) => (
					<Box
						data-intercom-area="dashboards"
						data-intercom-type="panel"
						data-intercom-target="dashboards-panel"
						ref={provided.innerRef}
					>
						{sortedFolders.map((folder, index) => (
							<Draggable key={folder.id} draggableId={`${folder.id}`} index={index}>
								{(provided, snapshot) => (
									<Box {...provided.dragHandleProps}>
										<Box ref={provided.innerRef} {...provided.draggableProps}>
											<DashboardFolder
												isDragging={snapshot.isDragging}
												index={index}
												dashboards={sortedDashboardNodes[index]}
												folder={folder}
											/>
										</Box>
									</Box>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Box>
				)}
			</Droppable>
		</DragDropContext>
	);

	if (isFoldersLoading) return <SkeletonFolder />;

	return droppableContent;
};
