import { ReactNode } from 'react';
import Box from 'src/common/components/Box';
import Flex from 'src/common/components/Flex';
import Typography from 'src/common/components/Typography';
import Button from 'src/common/components/Button';
import { AddSmall10 } from 'src/common/components/Icons';

export interface ListHeaderProps {
	icon: ReactNode;
	description: string;
	buttonLabel?: string;
	onClick?: VoidFunction;
}

export default function NoResults({ icon, description, buttonLabel, onClick }: ListHeaderProps) {
	return (
		<Flex
			marginTop={'130px'}
			textAlign={'center'}
			justifyContent={'center'}
			flexDirection={'column'}
			alignItems={'center'}
		>
			{icon}
			<Box gap="8px">
				<Typography maxWidth={'280px'} variant="Paragraph14R" color={'gray.1000'}>
					{description}
				</Typography>
			</Box>
			{buttonLabel && (
				<Button
					leftIcon={<AddSmall10 />}
					marginTop={'20px'}
					variant="solid"
					onClick={onClick}
					size="small"
					colorScheme="blue"
				>
					{buttonLabel}
				</Button>
			)}
		</Flex>
	);
}
