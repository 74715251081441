import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import Button from 'src/common/components/Button';
import Divider from 'src/common/components/Divider';
import {
	AddLarge,
	AppPanel,
	GoogleSheetsTransparent16,
	NoSearchResults,
	Search16,
	SlidersHorizontal16,
} from 'src/common/components/Icons';
import Input from 'src/common/components/Input';
import ListItem from 'src/common/components/ListItem';
import Scrollable from 'src/common/components/Scrollable';
import Tooltip from 'src/common/components/Tooltip';
import Typography from 'src/common/components/Typography';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import useDebouncedCallback from 'src/common/hooks/useDebouncedCallback';
import { useReportEvent } from 'src/services/analytics';
import { showIntercom } from 'src/services/intercom';
import { usePermissionCheck } from 'src/stores/environment';
import colors from 'src/style/colors';
import { Permissions } from 'src/types/environment';
import { NormalizedOntology, OntologyType, useOntologyPageState } from '../../hooks/useOntologyPageState';
import { useSetOntologyLocation } from '../../hooks/useOntologySearchParams';
import { OntologyEntityCard } from '../OntologyEntityCard';
import { TestIDs } from 'src/common/types/test-ids';
import NoResults from '../BuilderPanel/NoResults';

type SearchProps = {
	onSearch: (val: string) => void;
	onCreateNewOpen?: VoidFunction;
	isCollapsed: boolean;
	handleCollapsePanelClick: VoidFunction;
	isUpsertEntityModalOpened?: boolean;
	isEditable?: boolean;
	isSearchEnabled?: boolean;
};

function EntitiesActions({
	onSearch,
	onCreateNewOpen,
	isCollapsed,
	handleCollapsePanelClick,
	isUpsertEntityModalOpened,
	isEditable = true,
	isSearchEnabled = true,
}: SearchProps) {
	const [isActiveSearch, setIsActiveSearch] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeEntities);
	const isUpsertEntityModalEnabled = useFeatureFlag('pulse.sightfull2.upsertEntityModal.enable');

	const onClose = () => {
		if (isActiveSearch) {
			onSearch('');
			setSearchValue('');
			setIsActiveSearch(false);
		}
	};

	useKeyPress(['Escape'], onClose);

	const isAddButtonEnabled = hasWritePermission && onCreateNewOpen && isUpsertEntityModalEnabled;

	return (
		<Flex>
			{isActiveSearch ? (
				<Input
					isErasable={!!searchValue}
					autoFocus
					leftComponentPadding="38px"
					leftComponent={<Search16 />}
					leftComponentBorder={false}
					size="sm"
					placeholder={'Search entities'}
					value={searchValue}
					onEnter={onClose}
					onBlur={onClose}
					borderColor="gray.1000"
					backgroundColor={colors.selectedPanelItemColor}
					onChange={(val: string) => {
						setSearchValue(val);
						onSearch(val);
					}}
				/>
			) : (
				<Flex width={'100%'} justifyContent={'space-between'}>
					<Flex>
						{isAddButtonEnabled && (
							<Tooltip
								size="md"
								variant="fluid"
								label={!isUpsertEntityModalOpened && 'Create new'}
								placement={'right'}
								marginLeft={'6px'}
							>
								<Button
									testId={TestIDs.CREATE_NEW_ENTITY_BUTTON}
									isDisabled={!isEditable}
									size={'small'}
									variant={'outline'}
									onClick={onCreateNewOpen}
									isIconOnly
									colorScheme="gray"
									_hover={{ bgColor: colors.buttonPanelItemColor }}
									data-intercom-area={'ontology'}
									data-intercom-type={'button'}
									data-intercom-target={'create-entity'}
								>
									<AddLarge color={colors.gray['900']} />
								</Button>
							</Tooltip>
						)}
						<Tooltip size="md" variant="fluid" label={'Search'} placement={'right'} marginLeft={'6px'}>
							<Button
								isDisabled={!isSearchEnabled}
								isIconOnly
								variant="outline"
								onClick={() => setIsActiveSearch(true)}
								size="small"
								colorScheme="gray"
								_hover={{ bgColor: colors.buttonPanelItemColor }}
							>
								<Search16 color={isSearchEnabled ? colors.gray['900'] : colors.natural['400']} />
							</Button>
						</Tooltip>
					</Flex>

					<Tooltip
						size="md"
						variant="fluid"
						label={isCollapsed ? 'Expand' : 'Collapse'}
						placement={'right'}
						marginLeft={'6px'}
					>
						<Button
							size={'small'}
							variant={'outline'}
							onClick={handleCollapsePanelClick}
							isIconOnly={true}
							colorScheme="gray"
							bgColor={!isCollapsed ? colors.selectedPanelItemColor : 'transparent'}
							_hover={{ bgColor: colors.buttonPanelItemColor }}
						>
							<AppPanel color={isCollapsed ? colors.gray['900'] : colors.gray['1000']} />
						</Button>
					</Tooltip>
				</Flex>
			)}
		</Flex>
	);
}

type Props = {
	onCreateNewOpen?: VoidFunction;
	isCollapsed: boolean;
	handleCollapsePanelClick: VoidFunction;
	isUpsertEntityModalOpened?: boolean;
};

const ontologyOptions: { type: OntologyType; name: string; prefixIcon: React.ReactNode }[] = [
	{ type: 'entity', name: 'Entities', prefixIcon: <GoogleSheetsTransparent16 /> },
	{ type: 'parameter', name: 'Parameters', prefixIcon: <SlidersHorizontal16 /> },
];

function OntologyTypeSelection({ ontologyType }: { ontologyType: OntologyType }) {
	const { reportEvent } = useReportEvent();

	const updateOntologyLocation = useSetOntologyLocation();
	const {
		ontologyPageState: { ontologyByType },
	} = useOntologyPageState();
	return (
		<Flex direction={'column'} alignItems={'flex-start'} alignSelf={'stretch'}>
			{ontologyOptions
				.filter((option) => ontologyByType[option.type].length > 0)
				.map((option) => {
					const isSelected = ontologyType === option.type;

					return (
						<ListItem
							key={`PanelOption${option.name}`}
							size="sm"
							label={option.name}
							prefixIcon={option.prefixIcon}
							color="gray.1000"
							onClick={() => {
								reportEvent({
									event: `ontology-section-switch`,
									metaData: {
										newSection: option.type,
										previousSection: ontologyType,
									},
								});

								updateOntologyLocation({
									ontologyType: option.type,
									ontologyName: ontologyByType[option.type][0].name,
								});
							}}
							state={isSelected ? 'selected' : 'enabled'}
							borderRadius="8px"
							isOntologyPanel={true}
						/>
					);
				})}
		</Flex>
	);
}

export function OntologyPanel({
	onCreateNewOpen,
	isCollapsed,
	handleCollapsePanelClick,
	isUpsertEntityModalOpened,
}: Props) {
	const {
		ontologyPageState: { ontologyType, allOntology, selectedOntology, isOntologyPageEditable },
	} = useOntologyPageState();

	const { reportEvent } = useReportEvent();

	const updateOntologyLocation = useSetOntologyLocation();

	const [searchTerm, setSearchTerm] = useState('');
	const onSearchType = (val: string) => {
		reportEvent({
			event: 'ontology-object-search-type',
			metaData: { searchTerm: val, objectType: 'entity' },
		});

		setSearchTerm(val);
	};

	const filterByName = (displayName: string, val: string) => displayName.toLowerCase().includes(val.toLowerCase());

	const filteredOntology = useMemo(
		() =>
			searchTerm.length == 0
				? allOntology
				: allOntology.filter((el) => filterByName(el.name, searchTerm) || filterByName(el.displayName, searchTerm)),
		[searchTerm, allOntology]
	);

	const debounceSearch = useDebouncedCallback(onSearchType, 500);

	const onOntologyClick = (ontology: NormalizedOntology) => {
		reportEvent({
			event: `ontology-object-clicked`,
			metaData: {
				objectType: ontologyType,
				parentEntity: ontology.name,
				objectName: ontology.displayName,
			},
		});
		updateOntologyLocation({ ontologyType, ontologyName: ontology.name });
	};

	const onInAppSupportClick = useCallback(() => {
		reportEvent({
			event: 'ontology-parameter-support',
		});

		showIntercom();
	}, [reportEvent]);

	const isPageEmpty = !allOntology.length;

	const createEntityLabel = (
		<Flex backgroundColor={'rgba(206, 206, 206, 0.26)'} borderRadius={'8px'} padding={'12px 16px'}>
			<Typography variant="DesktopH8Regular" color="natural.600">
				No entities
			</Typography>
		</Flex>
	);

	const ontologyContent = (
		<Scrollable>
			{filteredOntology.length ? (
				filteredOntology.map((ontology) => (
					<OntologyEntityCard
						isSelected={selectedOntology?.name === ontology.name}
						onClick={() => onOntologyClick(ontology)}
						ontology={ontology}
						key={ontology.name}
						isFullyDefined={ontology.isFullyDefined}
					/>
				))
			) : (
				<Box marginTop={'130px'}>
					<NoResults
						icon={
							<Box marginBottom={'20px'}>
								<NoSearchResults />
							</Box>
						}
						description="We didn’t find any results, try adjusting your search to find what you're looking for."
					/>
				</Box>
			)}
		</Scrollable>
	);

	return (
		<Flex flexDirection={'column'} height={'100%'} gap="8px" paddingBottom={'16px'}>
			<EntitiesActions
				isEditable={isOntologyPageEditable}
				isSearchEnabled={!isPageEmpty}
				handleCollapsePanelClick={handleCollapsePanelClick}
				isCollapsed={isCollapsed}
				onCreateNewOpen={onCreateNewOpen}
				onSearch={(val: string) => debounceSearch(val)}
				isUpsertEntityModalOpened={isUpsertEntityModalOpened}
			/>
			<OntologyTypeSelection ontologyType={ontologyType} />
			{!isPageEmpty && <Divider direction="horizontal" />}
			<Flex paddingTop={'8px'} overflowY="auto" flexDirection={'column'} flex={1}>
				{isPageEmpty ? createEntityLabel : ontologyContent}
			</Flex>
			{ontologyType == 'parameter' && (
				<Flex
					direction={'column'}
					paddingY="16px"
					// TODO: Verify with Ben padding is OK, should be 16 but text does not fit
					paddingX="10px"
					backgroundColor={colors.selectedPanelItemColor}
					justifyContent={'center'}
					alignItems={'center'}
					width={'100%'}
					gap="4px"
					borderRadius={'8px'}
				>
					<Typography variant="DesktopH10Bold" color="gray.1000">
						{"Can't find the parameter you need?"}
					</Typography>
					<Typography variant="Paragraph12R" color="gray.1000">
						Use our&nbsp;
						<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onInAppSupportClick}>
							in-app support
						</span>
						&nbsp;to set up a new one.
					</Typography>
				</Flex>
			)}
		</Flex>
	);
}
