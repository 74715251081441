import { PeriodRange } from '@sightfull/period-ranges';
import Flex from 'src/common/components/Flex';
import { Filter } from 'src/pages/MetricPage/utils/state.types';
import shadows from 'src/style/shadows';
import { GlobalFiltersBar } from './GlobalFilters/GlobalFiltersBar';
import { GlobalPeriodPicker } from './GlobalPeriods/GlobalPeriodPicker';
import { memo } from 'react';

type SelectorsBarProps = {
	onDialogOpen: VoidFunction;
	isDialogOpen: boolean;
	onRemoveFilter: (key: string) => void;
	filterObjects: string[];
	globalFilters: Filter[];
	paddingXInPx: number;
	selectedPeriodRange?: PeriodRange;
	onSelectPeriodRange: (periodRange?: PeriodRange) => void;
	isVisible: boolean;
};

function SelectorsBarComponent({
	onDialogOpen,
	isDialogOpen,
	onRemoveFilter,
	filterObjects: availableFilterObjects,
	globalFilters,
	paddingXInPx: paddingX,
	selectedPeriodRange,
	onSelectPeriodRange,
	isVisible,
}: SelectorsBarProps) {
	return (
		<Flex
			dir="row"
			height={isVisible ? '70px' : '0'}
			width="100%"
			alignItems={'center'}
			paddingX={`${paddingX}px`}
			boxShadow={shadows.borderBottom}
			gap="12px"
			transition={'padding 150ms, height 300ms'}
			zIndex={1}
			overflow="hidden"
		>
			<GlobalPeriodPicker onSelectPeriodRange={onSelectPeriodRange} selectedPeriodRange={selectedPeriodRange} />
			<GlobalFiltersBar
				onDialogOpen={onDialogOpen}
				filterObjects={availableFilterObjects}
				globalFilters={globalFilters}
				isDialogOpen={isDialogOpen}
				onRemoveFilter={onRemoveFilter}
			/>
		</Flex>
	);
}

export const SelectorsBar = memo(SelectorsBarComponent, (prevProps, nextProps) => {
	return prevProps.isVisible === nextProps.isVisible && prevProps.paddingXInPx === nextProps.paddingXInPx;
});
