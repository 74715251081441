import { Center, Flex } from '@chakra-ui/react';
import Typography from '@components/Typography';
import Button from 'src/common/components/Button';
import { useModal } from 'src/common/hooks/ui/useModal';
import { CollectionUpsertModal } from 'src/layout/Menu/NavigationDrawer/CollectionUpsertModal';
import { OkBig, Plus16 } from '../Icons';
import { useDashboardPageSearchParams } from 'src/pages/DashboardPage/hooks/useDashboardPageSearchParams';

export function FolderEmptyPage() {
	const { folderId } = useDashboardPageSearchParams();
	const { isOpen, onOpen, onClose } = useModal();

	return (
		<Center height={'100%'}>
			<Flex
				paddingBottom={'110px'}
				w={'100%'}
				height={'100%'}
				justifyContent={'center'}
				alignItems="center"
				direction="column"
			>
				<Flex gap={'20px'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
					<OkBig />
					<Flex flexDirection={'column'} gap={'4px'}>
						<Typography color={'gray.1000'} variant={'DesktopH7Medium'} textAlign="center">
							Create a dashboard
						</Typography>
						<Typography color={'gray.1000'} variant={'DesktopH7Regular'} textAlign="center">
							Start by creating one to organize your signals.
						</Typography>
					</Flex>
					<Button
						borderRadius={'8px'}
						leftIcon={<Plus16 />}
						variant="solid"
						onClick={onOpen}
						size="small"
						colorScheme="blue"
					>
						Create
					</Button>
				</Flex>
			</Flex>
			<CollectionUpsertModal folderId={folderId} isOpen={isOpen} onClose={onClose} collectionType={'dashboard'} />
		</Center>
	);
}
