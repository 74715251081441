import useNavigation from 'src/services/useNavigation';
import colors from 'src/style/colors';
import Flex from 'src/common/components/Flex';
import Box from 'src/common/components/Box';
import Typography from 'src/common/components/Typography';
import Button from 'src/common/components/Button';
import { EmptyPageFoldersImage } from 'src/common/components/Icons';

export const FolderDashboardEmptyPage = () => {
	const { navigate } = useNavigation();

	return (
		<Flex alignItems="center" justifyContent="center" padding="130px 32px 60px 32px">
			<Flex alignItems="center" maxWidth="490px" flexDirection="column">
				<Box marginBottom="44px">
					<EmptyPageFoldersImage />
				</Box>
				<Typography color={colors.gray[1000]} marginBottom="4px" variant="DesktopH7Medium">
					Empty dashboard.
				</Typography>
				<Typography color={colors.gray[1000]} marginBottom="20px" variant="DesktopH7Regular">
					Get started by publishing a metric.
				</Typography>
				<Button
					borderRadius={'8px'}
					variant="solid"
					onClick={() =>
						navigate({
							path: '/metrics',
						})
					}
					size="small"
					colorScheme="blue"
				>
					Open catalog
				</Button>
			</Flex>
		</Flex>
	);
};
