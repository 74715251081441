import { iconButtonTheme } from '@components/IconButton/iconButton.theme';
import { StyleProps } from 'src/style/types';
import { baseSchema } from './colorSchema';

export default {
	sizes: {
		xxxs: {
			borderRadius: '4px',
		},
		xxs: {
			fontSize: 'sm',
			borderRadius: '8px',
		},
		xs: {
			fontSize: 'sm',
			minHeight: '28px',
			maxHeight: '28px',
			minWidth: '24px',
			px: '12px',
			py: '4px',
			borderRadius: '8px',
		},
		inline: {
			fontSize: 'sm',
			minHeight: '32px',
			maxHeight: '32px',
			minWidth: '28px',
			px: '12px',
			py: '5px',
			borderRadius: '8px',
		},
		small: {
			fontSize: 'sm',
			minHeight: '36px',
			maxHeight: '36px',
			minWidth: '32px',
			px: '16px',
			py: '7px',
			borderRadius: '8px',
		},
		medium: {
			fontSize: 'md',
			px: '20px',
			py: '10px',
			borderRadius: '8px',
			minHeight: '42px',
			maxHeight: '42px',
		},
		large: {
			fontSize: 'sm',
			minHeight: '48px',
			maxHeight: '48px',
			minWidth: '36px',
			px: '24px',
			py: '13px',
			borderRadius: '8px',
		},
	},

	variants: {
		solid: (props: StyleProps) => {
			const schema = baseSchema.solid[props.colorScheme];

			return {
				bg: schema.normalBackground,
				border: `1px solid ${schema.normalBorderColor}`,
				color: schema.normalTextColor,
				_hover: {
					bg: schema.hoverBackground,
					boxShadow: schema.hoverShadow,
				},
				_active: {
					bg: schema.activeBackground,
					boxShadow: schema.activeShadow,
				},
				_disabled: {
					bg: schema.disabledBackground,
					color: schema.disabledTextColor,
					opacity: schema.disabledOpacity ?? 1,
					_loading: { opacity: 1 },
					pointerEvents: 'none',
				},
				_loading: {
					bg: schema.normalBackground,
					border: `1px solid ${schema.normalBorderColor}`,
					color: schema.normalTextColor,
				},
			};
		},
		ghost: (props: StyleProps) => {
			const schema = baseSchema.ghost[props.colorScheme];

			return {
				bg: 'transparent',
				border: '1px',
				borderColor: schema.normalBorderColor,
				color: schema.normalTextColor,
				_hover: {
					bg: schema.hoverBackground,
					color: schema.hoverTextColor,
					borderColor: schema.hoverBorderColor,
				},
				_active: {
					bg: schema.activeBackground,
					color: schema.activeTextColor,
					borderColor: schema.activeBorderColor,
					boxShadow: schema.activeShadow,
				},
				_disabled: {
					color: schema.disabledTextColor,
					borderColor: schema.disabledBorderColor,
					opacity: 1,
					pointerEvents: 'none',
				},
				_loading: {
					bg: 'transparent',
					color: schema.normalTextColor,
					borderColor: schema.normalBorderColor,
				},
			};
		},
		outline: (props: StyleProps) => {
			const schema = baseSchema.outline[props.colorScheme];

			return {
				bg: `transparent`,
				border: 'none',
				color: schema.normalTextColor,
				_hover: {
					bg: schema.hoverBackground,
					color: schema.activeTextColor,
				},
				_active: {
					bg: schema.activeBackground,
					color: schema.activeTextColor,
					boxShadow: schema.activeShadow,
				},
				_disabled: {
					color: schema.disabledTextColor,
					opacity: 1,
					pointerEvents: 'none',
				},
				_loading: {
					bg: `transparent`,
					color: schema.normalTextColor,
				},
			};
		},
		link: (props: StyleProps) => {
			const schema = baseSchema.outline[props.colorScheme];

			return {
				bg: `transparent`,
				border: 'none',
				color: schema.normalTextColor,
				path: {
					fill: schema.normalTextColor,
				},
				_hover: {
					bg: `transparent`,
					color: schema.hoverTextColor,
					path: {
						fill: schema.hoverTextColor,
					},
				},
				_active: {
					bg: `transparent`,
					color: schema.activeTextColor,
					path: {
						fill: schema.activeTextColor,
					},
				},
				_disabled: {
					color: schema.disabledTextColor,
					opacity: 1,
					pointerEvents: 'none',
					path: {
						fill: schema.disabledTextColor,
					},
				},
				_loading: {
					bg: `transparent`,
					color: schema.normalTextColor,
					path: {
						fill: schema.normalTextColor,
					},
				},
			};
		},
		...iconButtonTheme,
	},
};
