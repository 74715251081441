import { useDisclosure } from '@chakra-ui/react';
import Box from '@components/Box';
import { useMetricFilter } from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/useMetricFilter';
import {
	formatDimensionLabel,
	formatMetricFilterLabel,
	replaceLastCommaWithOr,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { useSemanticDefinitions } from '@hooks/stores/useSemanticDefinitions';
import { Abc16, Calendar16, HashMark16, Switch16 } from '@icons/index';
import { useIsFiltersV2Enabled } from '@pages/MetricPage/components/FiltersAndBreakdown/useIsFiltersV2Enabled';
import { Filter } from '@pages/MetricPage/utils/state.types';
import { resolveContextFromSemanticsAndName } from '@pages/OntologyPage/components/JoinKeys/utils';
import { useReportEvent } from '@services/analytics';
import { useCallback, useMemo } from 'react';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getContextCompletionTooltip } from 'src/lib/completions/widgetBuilder/contextTooltip';
import { DrilldownCard } from '../DrillDownCard';
import { DropdownStringEditor } from '../Editors/DropdownStringEditor';
import { Operator } from './Operator';

export const FilterDrilldownCard = ({
	filter,
	onRemove,
	onClick,
	isEditorAutoOpened,
	eventMetaData,
}: {
	filter: Filter;
	onRemove?: VoidFunction;
	onClick?: VoidFunction;
	isEditorAutoOpened?: boolean;
	eventMetaData: object;
}) => {
	const { reportEvent } = useReportEvent();
	const isFiltersV2Enabled = useIsFiltersV2Enabled();
	const { label, values } = filter;
	const formattedLabel = formatDimensionLabel(label);
	const {
		isOpen: isEditing,
		onClose: onCloseEditor,
		onOpen: onOpenEditor,
	} = useDisclosure({ defaultIsOpen: isEditorAutoOpened });

	const {
		isLoadingDimensionValues,
		options,
		updateFilter,
		semanticDefinitionsForEntityDimension,
		entityToFetch,
		dimensionToFetch,
	} = useMetricFilter({
		filter,
	});
	const { semanticDefinitions } = useSemanticDefinitions();
	const dimensionType = semanticDefinitionsForEntityDimension?.type;

	const extendedEventMetaData = {
		...eventMetaData,
		dimension: filter?.key,
		dimensionType,
	};

	const texts = useMemo(() => {
		if (isFiltersV2Enabled) {
			const firstCommaSeparatedValuesLabels = values?.map(formatMetricFilterLabel).join(', ');
			const displayValue = values && replaceLastCommaWithOr(firstCommaSeparatedValuesLabels);

			return {
				title: formattedLabel,
				tooltip: displayValue ? firstCommaSeparatedValuesLabels : formattedLabel,
				displayValue,
			};
		} else {
			const valuesSelectedCount = values?.length;
			const maxValuesToDisplay = 5;
			const extraMissingValues =
				valuesSelectedCount && valuesSelectedCount > maxValuesToDisplay
					? `, +${valuesSelectedCount - maxValuesToDisplay} more`
					: '';
			const firstCommaSeparatedValues = values
				?.slice(0, maxValuesToDisplay)
				.map((v) => v?.toString() || '')
				.join(', ');

			const displayValue = (values && firstCommaSeparatedValues) ?? '';
			const title = values ? `${label} (${values.length})` : label;

			return {
				title,
				tooltip: values ? `${firstCommaSeparatedValues}${extraMissingValues}` : label,
				displayValue,
			};
		}
	}, [values, label, formattedLabel, isFiltersV2Enabled]);

	const { title, tooltip, displayValue } = texts;

	const icon = useMemo(() => {
		switch (dimensionType) {
			case 'string':
				return <Abc16 />;
			case 'number':
				return <HashMark16 />;
			case 'bool':
			case 'boolean':
				return <Switch16 />;
			case 'date':
			case 'timestamp':
			case 'timestampz':
			case 'timestamptz':
				return <Calendar16 />;

			default:
				return <Box w={'16px'} h={'16px'}></Box>;
		}
	}, [dimensionType]);

	// TA1.0
	const onEdit = () => {
		reportEvent({ event: 'filters-edit-values', metaData: extendedEventMetaData });
		onClick?.();
	};

	const onApply = useCallback(
		(values: (string | null)[]) => {
			updateFilter(values);
		},
		[updateFilter]
	);

	return (
		<DrilldownCard
			icon={icon}
			onRemove={onRemove}
			removeTooltip={'Remove filter'}
			title={title}
			eventMetaData={extendedEventMetaData}
			valueEditor={
				<DropdownStringEditor
					options={options}
					displayValue={displayValue}
					values={values}
					onEdit={onEdit}
					onApply={onApply}
					isEnabled={isFiltersV2Enabled}
					applyButtonLabel={'Add'}
					prefixComponent={<Operator operator={'Is'} />}
					defaultIsEditing={isEditorAutoOpened}
					tooltip={isFiltersV2Enabled ? 'Edit values' : tooltip}
					isLoadingOptions={isLoadingDimensionValues}
					placeholder={'Search for values'}
					isSelectable
					eventName={'filters-apply-filter'}
					eventMetaData={eventMetaData}
					searchValuesEventMetaData={{ event: 'filters-search-values', metaData: eventMetaData }}
					selectAllEventMetaData={{ event: 'filters-select-all-values-toggle', metaData: eventMetaData }}
					selectAllMatchingEventMetaData={{ event: 'filters-select-matching-values-toggle', metaData: eventMetaData }}
					isEditing={isEditing}
					onOpenEditor={onOpenEditor}
					onCloseEditor={onCloseEditor}
					isIntercomDetectable={true}
				/>
			}
			isEditing={isEditing}
			titleHoverTooltipBuilder={() => {
				if (!dimensionToFetch || !semanticDefinitions || !entityToFetch) return;
				const context = resolveContextFromSemanticsAndName(semanticDefinitions, entityToFetch, dimensionToFetch);
				if (!context) return;
				return (
					<MonacoTooltipComponent tooltipMarkdownString={getContextCompletionTooltip(context, dimensionToFetch)} />
				);
			}}
		/>
	);
};
