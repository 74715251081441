import { ReactNode } from 'react';
import { Abc16, Boolean16, Calendar16, HashMark16 } from 'src/common/components/Icons';
import { CloudSupportedOperators, SupportedDimensionTypes } from 'src/generated/graphql';
import { DimensionType } from 'src/lib/completions/semanticTypes/normalization.schema';
import { FilterV2Value } from 'src/pages/MetricPage/utils/state.types';
import { FilterBooleanEditor } from './Editors/FilterBooleanEditor';
import { FilterDateBetweenEditor } from './Editors/FilterDateBetweenEditor';
import { FilterDateRelativeEditor } from './Editors/FilterDateRelativeEditor';
import { FilterDateSingleEditor } from './Editors/FilterDateSingleEditor';
import { FilterInputNumberEditor } from './Editors/FilterInputNumberEditor';
import { FilterInputStringEditor } from './Editors/FilterInputStringEditor';
import { FilterStringDropdownEditor } from './Editors/FilterStringDropdownEditor';
import { FilterTwoNumbersEditor } from './Editors/FilterTwoNumbersEditor';
import { ValueEditorProps } from './Editors/ValueEditorByFilter';

export const FILTER_NULL_OPTION_NAME = '(NULL)';
export const FILTER_EMPTY_STRING_OPTION_NAME = '(empty string)';
export const FILTER_NULL_OPTION = {
	title: FILTER_NULL_OPTION_NAME,
	value: null,
};
export const FILTER_EMPTY_STRING_OPTION = { title: FILTER_EMPTY_STRING_OPTION_NAME, value: '' };

export const dimensionTypeToFilterType: { [t in DimensionType]: SupportedDimensionTypes } = {
	bool: SupportedDimensionTypes.Bool,
	string: SupportedDimensionTypes.String,
	number: SupportedDimensionTypes.Number,
	boolean: SupportedDimensionTypes.Bool,
	date: SupportedDimensionTypes.Date,
	timestamp: SupportedDimensionTypes.Date,
	timestampz: SupportedDimensionTypes.Date,
	timestamptz: SupportedDimensionTypes.Date,
};

export const defaultOperatorByFilterType: { [t in SupportedDimensionTypes]: CloudSupportedOperators } = {
	[SupportedDimensionTypes.Bool]: CloudSupportedOperators.OneOf,
	[SupportedDimensionTypes.Number]: CloudSupportedOperators.OneOf,
	[SupportedDimensionTypes.String]: CloudSupportedOperators.OneOf,
	[SupportedDimensionTypes.Date]: CloudSupportedOperators.InTheLast,
};

export const supportedOperatorsByFilterType: { [t in SupportedDimensionTypes]: CloudSupportedOperators[] } = {
	[SupportedDimensionTypes.Bool]: [CloudSupportedOperators.OneOf],
	[SupportedDimensionTypes.Number]: [
		CloudSupportedOperators.OneOf,
		CloudSupportedOperators.NotOneOf,
		CloudSupportedOperators.GreaterThan,
		CloudSupportedOperators.GreaterThanOrEqualTo,
		CloudSupportedOperators.LessThan,
		CloudSupportedOperators.LessThanOrEqualTo,
		CloudSupportedOperators.Between,
		CloudSupportedOperators.NotBetween,
		CloudSupportedOperators.IsNotNull,
		CloudSupportedOperators.IsNull,
	],
	[SupportedDimensionTypes.String]: [
		CloudSupportedOperators.OneOf,
		CloudSupportedOperators.NotOneOf,
		CloudSupportedOperators.Contains,
		CloudSupportedOperators.NotContains,
		CloudSupportedOperators.IsNotNull,
		CloudSupportedOperators.IsNull,
	],
	[SupportedDimensionTypes.Date]: [
		CloudSupportedOperators.InTheLast,
		CloudSupportedOperators.Between,
		CloudSupportedOperators.NotBetween,
		CloudSupportedOperators.OneOf,
		CloudSupportedOperators.BeforeTheLast,
		CloudSupportedOperators.LessThan,
		CloudSupportedOperators.GreaterThan,
		CloudSupportedOperators.InTheNext,
		CloudSupportedOperators.IsNotNull,
		CloudSupportedOperators.IsNull,
	],
} as const;

export const typeMappingByFilterType: {
	[t in SupportedDimensionTypes]: (v: FilterV2Value) => FilterV2Value;
} = {
	[SupportedDimensionTypes.Bool]: (v) => (v == null ? null : v === true || v == 'true'),
	[SupportedDimensionTypes.Number]: (v) => Number(v),
	[SupportedDimensionTypes.String]: (v) => (v == null ? v : String(v)),
	[SupportedDimensionTypes.Date]: (v) => String(v),
};

export const typeIcon: {
	[t in SupportedDimensionTypes]: ReactNode;
} = {
	[SupportedDimensionTypes.Bool]: Boolean16(),
	[SupportedDimensionTypes.Number]: HashMark16(),
	[SupportedDimensionTypes.String]: Abc16(),
	[SupportedDimensionTypes.Date]: Calendar16(),
};

export const requiredValuesCountPerOperator: {
	[t in CloudSupportedOperators]: number;
} = {
	[CloudSupportedOperators.Between]: 2,
	[CloudSupportedOperators.Contains]: 1,
	[CloudSupportedOperators.ContainsSensitive]: 1,
	[CloudSupportedOperators.GreaterThan]: 1,
	[CloudSupportedOperators.GreaterThanOrEqualTo]: 1,
	[CloudSupportedOperators.IsNotNull]: 0,
	[CloudSupportedOperators.IsNull]: 0,
	[CloudSupportedOperators.LessThan]: 1,
	[CloudSupportedOperators.LessThanOrEqualTo]: 1,
	[CloudSupportedOperators.NotBetween]: 2,
	[CloudSupportedOperators.NotContains]: 1,
	[CloudSupportedOperators.NotContainsSensitive]: 1,
	[CloudSupportedOperators.NotOneOf]: 1,
	[CloudSupportedOperators.NotOneOfSensitive]: 1,
	[CloudSupportedOperators.OneOf]: 1,
	[CloudSupportedOperators.OneOfSensitive]: 1,
	[CloudSupportedOperators.InTheNext]: 2,
	[CloudSupportedOperators.InTheLast]: 2,
	[CloudSupportedOperators.NotInTheLast]: 2,
	[CloudSupportedOperators.BeforeTheLast]: 2,
};

const operatorAndTypeToDisplayNameAndEditorMap: {
	[t in CloudSupportedOperators]: {
		[t in SupportedDimensionTypes]: [string, ((values: ValueEditorProps) => ReactNode) | undefined] | undefined;
	};
} = {
	[CloudSupportedOperators.OneOf]: {
		[SupportedDimensionTypes.Bool]: ['Is', FilterBooleanEditor],
		[SupportedDimensionTypes.Number]: ['Equals', FilterInputNumberEditor],
		[SupportedDimensionTypes.String]: ['Is', FilterStringDropdownEditor],
		[SupportedDimensionTypes.Date]: ['On', FilterDateSingleEditor],
	},
	[CloudSupportedOperators.OneOfSensitive]: {
		[SupportedDimensionTypes.Bool]: ['Is', FilterBooleanEditor],
		[SupportedDimensionTypes.Number]: ['Equals', FilterStringDropdownEditor],
		[SupportedDimensionTypes.String]: ['Is', FilterStringDropdownEditor],
		[SupportedDimensionTypes.Date]: ['On', FilterDateSingleEditor],
	},
	[CloudSupportedOperators.Contains]: {
		[SupportedDimensionTypes.Bool]: ['Contains', FilterBooleanEditor],
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: ['Contains', FilterInputStringEditor],
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.NotContains]: {
		[SupportedDimensionTypes.Bool]: ['Does not contain', FilterBooleanEditor],
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: ['Does not contain', FilterInputStringEditor],
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.NotContainsSensitive]: {
		[SupportedDimensionTypes.Bool]: ['Does not contain', FilterBooleanEditor],
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: ['Does not contain', FilterInputStringEditor],
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.NotOneOfSensitive]: {
		[SupportedDimensionTypes.Bool]: ['Is not', FilterBooleanEditor],
		[SupportedDimensionTypes.Number]: ['Not equal', FilterStringDropdownEditor],
		[SupportedDimensionTypes.String]: ['Is not', FilterStringDropdownEditor],
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.ContainsSensitive]: {
		[SupportedDimensionTypes.Bool]: ['Contains', FilterBooleanEditor],
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: ['Contains', FilterInputStringEditor],
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.GreaterThan]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Greater than', FilterInputNumberEditor],
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: ['After', FilterDateSingleEditor],
	},
	[CloudSupportedOperators.GreaterThanOrEqualTo]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Greater than or equal to', FilterInputNumberEditor],
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.LessThan]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Less than', FilterInputNumberEditor],
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: ['Before', FilterDateSingleEditor],
	},
	[CloudSupportedOperators.LessThanOrEqualTo]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Less than or equal to', FilterInputNumberEditor],
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.Between]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Between', FilterTwoNumbersEditor],
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: ['Between', FilterDateBetweenEditor],
	},
	[CloudSupportedOperators.IsNull]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Is null', undefined],
		[SupportedDimensionTypes.String]: ['Is null', undefined],
		[SupportedDimensionTypes.Date]: ['Is null', undefined],
	},
	[CloudSupportedOperators.IsNotNull]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Is not null', undefined],
		[SupportedDimensionTypes.String]: ['Is not null', undefined],
		[SupportedDimensionTypes.Date]: ['Is not null', undefined],
	},
	[CloudSupportedOperators.NotBetween]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Not between', FilterTwoNumbersEditor],
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: ['Not between', FilterDateBetweenEditor],
	},
	[CloudSupportedOperators.NotOneOf]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: ['Not equal', FilterInputNumberEditor],
		[SupportedDimensionTypes.String]: ['Is not', FilterStringDropdownEditor],
		[SupportedDimensionTypes.Date]: ['Not on', FilterDateSingleEditor],
	},
	[CloudSupportedOperators.InTheLast]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: ['Last', FilterDateRelativeEditor],
	},
	[CloudSupportedOperators.NotInTheLast]: {
		// Product killed this operator after development
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: undefined,
	},
	[CloudSupportedOperators.BeforeTheLast]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: ['Before the last', FilterDateRelativeEditor],
	},
	[CloudSupportedOperators.InTheNext]: {
		[SupportedDimensionTypes.Bool]: undefined,
		[SupportedDimensionTypes.Number]: undefined,
		[SupportedDimensionTypes.String]: undefined,
		[SupportedDimensionTypes.Date]: ['In the next', FilterDateRelativeEditor],
	},
} as const;
export const getOperatorDisplayName = (operator: CloudSupportedOperators, type: SupportedDimensionTypes) => {
	return operatorAndTypeToDisplayNameAndEditorMap[operator][type]?.[0] ?? '';
};
export const getOperatorEditorComponent = (operator: CloudSupportedOperators, type: SupportedDimensionTypes) => {
	return operatorAndTypeToDisplayNameAndEditorMap[operator][type]?.[1];
};

export const relativeDateOperators = [
	CloudSupportedOperators.InTheNext,
	CloudSupportedOperators.InTheLast,
	CloudSupportedOperators.NotInTheLast,
	CloudSupportedOperators.BeforeTheLast,
];

export const defaultRelativeDateValue = 30;
export const defaultRelativeDateUnit = 'day';
