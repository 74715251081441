import Flex from '@components/Flex';
import classes from '@components/LeftExpandCollapsePanel/LeftExpandCollapsePanel.module.scss';
import Typography from '@components/Typography';
import { PlusMed16 } from '@icons/index';
import { ListType } from '@pages/MetricPage/components/FiltersAndBreakdown/types';
import classNames from 'classnames';

export const AddDrillDownCard = ({
	type,
	onClick,
	isDisabled,
	dataIntercomTarget,
}: {
	type: ListType;
	onClick: (type: ListType) => void;
	isDisabled?: boolean;
	dataIntercomTarget?: string;
}) => {
	return (
		<Flex
			className={classNames(classes.card, classes.addCard, {
				[classes.isDisabled]: isDisabled,
			})}
			alignItems={'center'}
			gap={'8px'}
			cursor={'pointer'}
			onClick={() => !isDisabled && onClick(type)}
			data-intercom-area={'metric'}
			data-intercom-type={'button'}
			data-intercom-target={dataIntercomTarget}
		>
			<PlusMed16 />
			<Typography variant={'DesktopH8Regular'}>Add {type}</Typography>
		</Flex>
	);
};
