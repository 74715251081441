import { Box, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import Button from '@components/Button';
import { EmojiPicker } from '@components/EmojiPicker';
import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import colors from 'src/style/colors';
import shadows from 'src/style/shadows';
import { initialFolderData } from '../LeftSidePanelV2/DashboardFolder/UpsertFolderModal';
import { useReportEvent } from 'src/services/analytics';
import useUser from 'src/common/hooks/stores/useUser';
import { useGetAllFoldersQuery } from 'src/generated/graphql';
import { AdvancedSelect } from '../AdvancedSelect';
import { SelectLabel } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import { ChevronDown16 } from '../Icons';
import _ from 'lodash';
import { FolderData } from '../LeftSidePanelV2/DashboardFolder/types';

export const FolderSelect = ({
	isInitSelect = false,
	setFolderData,
	folderData,
	flow,
}: {
	isInitSelect?: boolean;
	setFolderData: (prevFolderData: FolderData | ((prevFolderData: FolderData) => FolderData)) => void;
	folderData?: FolderData;
	flow?: 'edit' | 'create' | 'move';
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [folderSelectState, setFolderSelectState] = useState({ isActive: false, isSelect: isInitSelect });
	const { isActive, isSelect } = folderSelectState;
	const selectorBorderColor = isActive ? colors.gray[900] : colors.gray[300];
	const borderColor = isActive ? colors.gray[900] : colors.gray[500];
	const emojiPickerBorderColor = isOpen ? colors.gray[900] : 'transparent';

	const [{ id: my_id }] = useUser();

	const { data: foldersList, loading: foldersListLoading } = useGetAllFoldersQuery({
		variables: { my_id: my_id },
		fetchPolicy: 'no-cache',
	});

	const folderOptions = useMemo(
		() =>
			_.sortBy(foldersList?.folders, 'order').map((folder) => ({
				value: folder.id,
				label: folder.name,
				icon: (
					<Box lineHeight={'20px'} fontSize={'20px'}>
						{folder.emoji}
					</Box>
				),
			})),
		[foldersList?.folders]
	);

	const folderControlledValue = useMemo(
		() => folderOptions?.find((el) => el.value === folderData?.id),
		[folderData?.id, folderOptions]
	);

	useEffect(() => {
		setTimeout(() => inputRef?.current?.focus(), 100);
	}, []);

	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });

	const onEmojiSelect = (emoji: string) =>
		setFolderData((prevFolderData: FolderData) => ({
			...prevFolderData,
			emoji,
		}));

	const bottomDropdownContent = (
		<Flex
			position={'absolute'}
			bottom={0}
			left={0}
			border={`1px solid ${colors.gray[300]}`}
			onClick={() => {
				reportEvent({
					event: 'sidebar-create-new-folder-via-drop-down',
					metaData: {
						flow,
					},
				});
				setFolderSelectState((prevState) => ({ ...prevState, isSelect: false }));
				setFolderData(initialFolderData);
			}}
			transition={'0.2s'}
			cursor={'pointer'}
			backgroundColor={colors.gray[100]}
			_hover={{ backgroundColor: 'transparent' }}
			padding={'12px 0px'}
			borderBottomRadius={'8px'}
			width={'100%'}
		>
			<Typography width={'100%'} textAlign={'center'} variant={'DesktopH8Medium'} color={'gray.1000'}>
				Create new folder
			</Typography>
		</Flex>
	);

	const SelectLayout = (
		<>
			<SelectLabel color={'gray.1100'} text="Folder" />
			<AdvancedSelect
				leftComponentBorder={false}
				onReportEvent={(input, label) => {
					if (flow === 'move') {
						reportEvent({
							event: 'sidebar-move-dashboard-selected',
							metaData: { field: 'folder', input: input, folderName: label, itemType: 'folder' },
						});
					} else {
						reportEvent({
							event: 'sidebar-dashboard-modal-input-provided',
							metaData: { field: 'name', input: label, flow, itemType: 'folder' },
						});
					}
				}}
				onSearchReportEvent={(searchTerm) => {
					reportEvent({
						event: 'sidebar-search-folder',
						metaData: { searchTerm, flow },
					});
				}}
				isDisabled={foldersListLoading}
				onChange={(folder) => setFolderData?.({ id: folder.value, folderName: folder.label || '' })}
				placeholder="Select"
				options={folderOptions}
				controlledValue={folderControlledValue}
				dataIntercomTarget="folder"
				bottomDropdownContent={bottomDropdownContent}
			/>
		</>
	);

	const InputLayout = (
		<>
			<Typography variant={'DesktopH10Regular'} color={'gray.1000'}>
				{'Folder name'}
			</Typography>

			<InputGroup
				display="flex"
				alignItems="center"
				minW={'min-content'}
				p={`0px 0px 0px 58px`}
				border="1px"
				borderColor={selectorBorderColor}
				borderRadius="8px"
				borderLeft={`1px solid ${isOpen ? 'transparent' : selectorBorderColor}`}
				boxShadow={isActive && !isOpen ? shadows.focus.medium.gray[100] : 'none'}
				_hover={{
					borderColor: borderColor,
					borderLeft: `1px solid ${isOpen ? 'transparent' : borderColor}`,
				}}
			>
				<InputLeftElement top={'50%'} transform={'translate(-1px, -50%)'} h="min-content" w="min-content">
					<Box
						borderLeftRadius={'8px'}
						border={`1px solid ${emojiPickerBorderColor}`}
						borderRight={`1px solid ${isOpen ? colors.gray[900] : colors.gray[300]}`}
					>
						<EmojiPicker
							defaultEmojiValue={folderData?.emoji}
							onEmojiSelect={onEmojiSelect}
							isOpen={isOpen}
							onOpen={onOpen}
							onClose={onClose}
						/>
					</Box>
				</InputLeftElement>

				<Typography variant="Paragraph14R" width={'100%'}>
					<Input
						ref={inputRef}
						onFocus={() => setFolderSelectState((prevState) => ({ ...prevState, isActive: true }))}
						onBlur={(e) => {
							reportEvent({
								event: 'sidebar-dashboard-modal-input-provided',
								metaData: { field: 'name', input: e.target.value, flow, itemType: 'folder' },
							});
							setFolderSelectState((prevState) => ({ ...prevState, isActive: false }));
						}}
						ml="0"
						pl="0"
						border="none"
						boxShadow={'none'}
						defaultValue={folderData?.folderName || ''}
						focusBorderColor="none"
						onChange={(e) => setFolderData((data) => ({ ...data, folderName: e.target.value }))}
						placeholder={'Create new folder'}
						color={'gray.1000'}
						_focusVisible={{ _placeholder: { color: 'gray.600' }, shadow: 'none' }}
						_placeholder={{ color: 'gray.600' }}
						fontSize={'14px'}
					/>
				</Typography>
				{isInitSelect && (
					<InputRightElement cursor={'pointer'}>
						<Button
							size={'xs'}
							variant={'outline'}
							colorScheme={'lightGray'}
							isIconOnly
							_hover={{ bgColor: colors.buttonPanelItemColor }}
							mixBlendMode={'multiply'}
							onClick={() => {
								setFolderSelectState((prevState) => ({ ...prevState, isSelect: true }));
								setFolderData(initialFolderData);
							}}
						>
							<ChevronDown16 color={colors.gray[1000]} />
						</Button>
					</InputRightElement>
				)}
			</InputGroup>
		</>
	);

	return (
		<Flex flexDirection={'column'} gap={'8px'}>
			{isSelect ? SelectLayout : InputLayout}
		</Flex>
	);
};
