import { Box } from '@chakra-ui/react';
import ListItem from '@components/ListItem';
import { EmptyState } from '@components/SearchDropdown/EmptyState';
import { Option } from '@components/SearchDropdown/types';
import Skeleton from '@components/Skeleton';
import useDebouncedCallback from '@hooks/useDebouncedCallback';
import { EventMetaDataObject, useReportEvent } from '@services/analytics';
import classnames from 'classnames';
import { ReactNode, RefObject, useMemo, useRef, useState } from 'react';
import colors from 'src/style/colors';
import { Search } from '../Search';
import Typography from '../Typography';
import classes from './SearchDropdown.module.scss';
import { SelectableSearchDropdown } from './SelectableSearchDropdown';

export type SearchDropdownProps = {
	children?: ReactNode;
	options: Option[];
	isLoadingOptions?: boolean;
	onOptionClick?: (el: Option) => void;
	placeholder?: string;
	optionsTitle?: string;
	initialFocusRef?: RefObject<{ focus(): void }>;
	onInputChangeCallback?: (value: string) => void;
	isSearchable?: boolean;
	isSelectable?: boolean;
	isMultiSelect?: boolean;
	selectedValues?: (string | null)[];
	setSelectedValues?: (ids: (string | null)[]) => void;
	searchValuesEventMetaData?: EventMetaDataObject;
	selectAllEventMetaData?: EventMetaDataObject;
	selectAllMatchingEventMetaData?: EventMetaDataObject;
};

export const SearchDropdown = ({
	children,
	options,
	isLoadingOptions = false,
	onOptionClick,
	placeholder = 'Search',
	optionsTitle,
	initialFocusRef,
	onInputChangeCallback,
	isSearchable = true,
	isSelectable = false,
	isMultiSelect = true,
	selectedValues = [],
	setSelectedValues,
	searchValuesEventMetaData,
	selectAllEventMetaData,
	selectAllMatchingEventMetaData,
}: SearchDropdownProps) => {
	const { reportEvent } = useReportEvent();
	const { dropdownMenu, menuSearch, optionsList } = classes;
	const [searchValue, setSearchValue] = useState('');
	const inputRef = useRef<HTMLDivElement>(null);
	const localSelectedValues = useRef(selectedValues).current;
	const debouncedSearchValueUpdateReport = useDebouncedCallback(
		(value: string) =>
			reportEvent({
				...searchValuesEventMetaData,
				metaData: {
					searchTerm: value,
					...searchValuesEventMetaData?.metaData,
				},
			}),
		500
	);

	const optionsArray = useMemo(() => {
		const filteredArray =
			options?.filter((el) => el.title?.toLocaleLowerCase().includes(searchValue.toLowerCase())) || [];

		if (!isSelectable) {
			return filteredArray;
		} else {
			const customValues = localSelectedValues
				.filter(
					(id) =>
						id !== null &&
						id.toLocaleLowerCase().includes(searchValue.toLowerCase()) &&
						!filteredArray.some((el) => el.value === id)
				)
				.map((id) => ({ title: String(id), value: id }));

			return [...filteredArray, ...customValues].sort((a, b) => {
				if (localSelectedValues.includes(a.value) && !localSelectedValues.includes(b.value)) {
					return -1;
				} else if (!localSelectedValues.includes(a.value) && localSelectedValues.includes(b.value)) {
					return 1;
				} else {
					return 0;
				}
			});
		}
	}, [localSelectedValues, options, isSelectable, searchValue]);

	const clickableOptionsArray = useMemo(() => {
		return optionsArray.map((el) => (
			<ListItem
				hoverColor="blue.100"
				color="gray.1000"
				key={el.value}
				size={'sm'}
				label={el.title ?? el.value}
				onClick={(event) => {
					event?.stopPropagation();
					onOptionClick?.(el);
				}}
				hasRoundedCorners
			/>
		));
	}, [onOptionClick, optionsArray]);

	const optionElements = useMemo(() => {
		if (!isSelectable) {
			return clickableOptionsArray;
		} else {
			return (
				<SelectableSearchDropdown
					selectedValues={selectedValues}
					setSelectedValues={setSelectedValues}
					optionsArray={optionsArray}
					isMultiSelect={isMultiSelect}
					searchValue={searchValue}
					selectAllEventMetadata={selectAllEventMetaData}
					selectAllMatchingEventMetadata={selectAllMatchingEventMetaData}
				/>
			);
		}
	}, [
		clickableOptionsArray,
		isMultiSelect,
		isSelectable,
		optionsArray,
		searchValue,
		selectAllEventMetaData,
		selectAllMatchingEventMetaData,
		selectedValues,
		setSelectedValues,
	]);

	const optionsContent = useMemo(() => {
		if (isLoadingOptions) {
			return Array.from({ length: 7 }).map((_, index) => <Skeleton key={index} className={classes.optionSkeleton} />);
		}

		const isEmptyOptionsList = !optionsArray?.length && (!isSelectable || !searchValue);

		return isEmptyOptionsList ? <EmptyState /> : optionElements;
	}, [isLoadingOptions, optionsArray, optionElements, searchValue, isSelectable]);

	return (
		<Box className={dropdownMenu} ref={inputRef}>
			{isSearchable && (
				<Box className={menuSearch}>
					<Search
						height="44px"
						isDeBounced={false}
						initialValue={searchValue}
						isAutoFocused
						onChange={(value) => {
							setSearchValue(value);
							debouncedSearchValueUpdateReport(value);
							onInputChangeCallback?.(value);
						}}
						isTransparent
						width="100%"
						placeholder={placeholder}
						fontSize={'14px'}
						initialFocusRef={initialFocusRef}
					/>
				</Box>
			)}

			<Box className={classnames(optionsList, { [classes.selectableList]: isSelectable })}>
				{!!optionsTitle && (
					<Typography variant={'DesktopH10Regular'} color={'gray.700'} mb={'8px'}>
						{optionsTitle}
					</Typography>
				)}
				{optionsContent}
			</Box>
			{!!children && (
				<Box
					backgroundColor={'gray.200'}
					borderTop={`1px solid ${colors.gray[300]}`}
					borderBottomLeftRadius={'8px'}
					borderBottomRightRadius={'8px'}
				>
					{children}
				</Box>
			)}
		</Box>
	);
};
