import * as DateFns from 'date-fns';
import { useCallback, useMemo } from 'react';
import DatePickerPopover from 'src/common/components/DatePickerPopover';
import { toIsoDateWithoutTimezone } from 'src/normalize/utils';
import { EditableValueContainer } from '../../Editors/EditableValueContainer';
import { StringValueDisplay } from '../../Editors/StringValueDisplay';

import { DATE_PRETTY_FORMAT } from 'src/common/utils/consts';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterDateBetweenEditor({
	values,
	onApply,
	prefixComponent,
	isEditing,
	onOpenEditor,
	onCloseEditor,
}: ValueEditorProps) {
	const displayValue = useMemo(
		() => values.map((v) => v && DateFns.format(new Date(v.toString()), DATE_PRETTY_FORMAT)).join(' - '),
		[values]
	);

	const applyDates = useCallback(
		({ fromDate, toDate }: { fromDate: Date | null; toDate: Date | null }) => {
			if (fromDate && toDate) onApply([toIsoDateWithoutTimezone(fromDate), toIsoDateWithoutTimezone(toDate)]);
			onCloseEditor();
		},
		[onApply, onCloseEditor]
	);

	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<DatePickerPopover
				isOpen={isEditing}
				fromDate={(values[0] && typeof values[0] == 'string' && new Date(values[0])) || null}
				toDate={(values[1] && typeof values[1] == 'string' && new Date(values[1])) || null}
				onCancel={onCloseEditor}
				isPeriodPicker={true}
				onApply={applyDates}
				triggerElement={
					<StringValueDisplay
						tooltip={displayValue}
						value={displayValue}
						hint={'Choose values'}
						onStartEditing={onOpenEditor}
						isEditing={isEditing}
						isEnabled={true}
						isIntercomDetectable={true}
					/>
				}
			/>
		</EditableValueContainer>
	);
}
