import { Option } from '@components/SearchDropdown/types';
import { ReactNode } from 'react';
import Box from 'src/common/components/Box';
import { CloudSupportedOperators } from 'src/generated/graphql';
import { FilterV2, FilterV2Value } from 'src/pages/MetricPage/utils/state.types';
import { getOperatorEditorComponent } from '../constants';

export type ValueEditorProps = {
	displayValue: string;
	values: FilterV2Value[];
	options: Option[];
	onApply: (values: FilterV2Value[]) => void;
	prefixComponent?: ReactNode;
	eventMetaData: object;
	isEditing: boolean;
	onOpenEditor: VoidFunction;
	onCloseEditor: VoidFunction;
	filter: FilterV2;
	isEditorAutoOpened?: boolean;
	isLoadingDimensionValues: boolean;
};

export function ValueEditorByFilter({ filter, ...valueProps }: ValueEditorProps) {
	if ([CloudSupportedOperators.IsNull, CloudSupportedOperators.IsNotNull].includes(filter.operator)) {
		return <Box paddingLeft={'26px'}>{valueProps.prefixComponent}</Box>;
	}

	return getOperatorEditorComponent(filter.operator, filter.type)?.({ ...valueProps, filter });
}
