import {
	EntityNodeType,
	SemanticObjectNodeType,
	MetricNodeType,
	SemanticObjectEdgeType,
	MetricToMetricEdgeType,
	EntityToMetricEdgeType,
	EntityToEntityEdgeType,
	SemanticMetricDimensionType,
} from '@components/SemanticFlow';
import { LineageMetricDefinition } from '../../../generated/graphql';

import { EntityWithMetrics } from '../../../lib/completions/semanticTypes';
import { MetricMetadataV2 } from '../../../types/metric';

export class MetricLineageGraphBuilder {
	private nodes: SemanticObjectNodeType[] = [];
	private edges: SemanticObjectEdgeType[] = [];
	private collectedEntities: Map<string, EntityNodeType> = new Map();

	constructor(
		private readonly allEntities: Record<string, EntityWithMetrics>,
		private readonly allMetrics: Record<string, MetricMetadataV2>
	) {}

	getNodesAndEdges() {
		return { nodes: this.nodes, edges: this.edges };
	}

	getMetricNodeId(metricName: string) {
		return `metric-${metricName}`;
	}

	getEntityNodeId(entityName: string) {
		return `entity-${entityName}`;
	}

	upsertEntityNode({ entity }: { entity: string }) {
		let entityNode = this.collectedEntities.get(entity);
		const entityNodeId = this.getEntityNodeId(entity);
		if (!entityNode) {
			const semanticEntity = this.allEntities[entity];
			const newEntityNode: EntityNodeType = {
				id: entityNodeId,
				type: 'entity',
				position: { x: 0, y: 0 },
				data: {
					entityName: entity,
					displayName: semanticEntity?.meta?.display_name ?? entity,
					description: semanticEntity
						? semanticEntity.meta?.description ?? 'No description'
						: 'This entity does not exist in the ontology',
					dimensions: new Set<string>(),
					hasPopup: true,
				},
			};
			this.nodes.push(newEntityNode);
			this.collectedEntities.set(entity, newEntityNode);
			entityNode = newEntityNode;
		}

		return { entityNodeId, entityNode };
	}

	upsertNode({ node }: { node: SemanticObjectNodeType }): SemanticObjectNodeType {
		const foundNode = this.nodes.find((n) => n.id === node.id);
		if (foundNode) return foundNode;
		this.nodes.push(node);
		return node;
	}

	upsertMetricNode({
		node,
		metric,
	}: {
		node: Pick<MetricNodeType, 'id' | 'data'>;
		metric?: MetricMetadataV2;
	}): SemanticObjectNodeType {
		return this.upsertNode({
			node: {
				id: node.id,
				type: 'metric',
				position: { x: 0, y: 0 },
				data: {
					...node.data,
					displayName: metric?.displayName ?? metric?.name,
					description: metric?.description,
					hasPopup: true,
				},
			},
		});
	}

	upsertEdge({ edge }: { edge: SemanticObjectEdgeType }): SemanticObjectEdgeType {
		const foundEdge = this.edges.find((e) => e.id === edge.id);
		if (foundEdge) return foundEdge;
		this.edges.push(edge);
		return edge;
	}

	upsertMetricToMetricEdge({ edge }: { edge: Pick<MetricToMetricEdgeType, 'id' | 'source' | 'target' | 'data'> }) {
		return this.upsertEdge({
			edge: {
				id: edge.id,
				type: 'metricToMetric',
				source: edge.source,
				target: edge.target,
				sourceHandle: 'right-center',
				targetHandle: 'left-center',
				data: edge.data,
			},
		});
	}

	upsertEntityToMetricEdge({ edge }: { edge: Pick<EntityToMetricEdgeType, 'id' | 'source' | 'target' | 'data'> }) {
		return this.upsertEdge({
			edge: {
				id: edge.id,
				type: 'entityToMetric',
				source: edge.source,
				target: edge.target,
				sourceHandle: 'right-center',
				targetHandle: 'left-center',
				data: edge.data,
			},
		});
	}

	upsertEntityToEntityEdge({ edge }: { edge: Pick<EntityToEntityEdgeType, 'id' | 'source' | 'target' | 'data'> }) {
		return this.upsertEdge({
			edge: {
				id: edge.id,
				type: 'entityToEntity',
				source: edge.source,
				target: edge.target,
				sourceHandle: 'right-center',
				targetHandle: 'left-center',
				data: edge.data,
			},
		});
	}

	upsertMetricDimensions({ dimensions }: { dimensions: SemanticMetricDimensionType[] }) {
		for (const dimension of dimensions) {
			const { entityNode } = this.upsertEntityNode({ entity: dimension.entity });
			if (!entityNode.data.dimensions.has(dimension.name)) {
				entityNode.data.dimensions.add(dimension.name);
			}

			dimension.relationships.forEach((rel) => {
				const targetEntity = this.allEntities[rel.entity];
				const referencedEntity = targetEntity?.relationships?.find((r) => r.name === rel.name)?.referenced_entity;
				const sourceEntity = referencedEntity ? this.allEntities[referencedEntity] : undefined;
				if (sourceEntity && targetEntity) {
					this.upsertEntityNode({ entity: sourceEntity.name });
					this.upsertEntityNode({ entity: targetEntity.name });
					const sourceEntityId = this.getEntityNodeId(sourceEntity.name);
					const targetEntityId = this.getEntityNodeId(targetEntity.name);
					const edge = this.upsertEntityToEntityEdge({
						edge: {
							id: `${sourceEntityId}-${targetEntityId}}`,
							source: sourceEntityId,
							target: targetEntityId,
							data: { relationships: [] },
						},
					});
					const edgeRelationships = edge.data!.relationships!;
					if (!edgeRelationships.find((r) => r.name === rel.name)) {
						edgeRelationships.push(rel);
					}
				}
			});
		}
	}

	buildGraph(metrics: LineageMetricDefinition[]) {
		const metricLineageEntries = metrics.reduce((acc: Record<string, LineageMetricDefinition>, metric) => {
			acc[metric.name] = metric;
			return acc;
		}, {});

		for (const metric of metrics) {
			const { name, entity, direction, metrics, dimensions } = metric;
			const currentMetric = this.allMetrics[name];
			const metricNodeId = this.getMetricNodeId(name);
			this.upsertMetricNode({
				metric: currentMetric,
				node: {
					id: metricNodeId,
					data: {
						metricName: name,
						highlighted: direction === 'root',
						entity,
						dimensions,
						direction,
					},
				},
			});

			if (direction !== 'downstream') {
				const { entityNodeId } = this.upsertEntityNode({ entity });
				this.upsertEntityToMetricEdge({
					edge: {
						id: `${entityNodeId}-${metricNodeId}`,
						source: entityNodeId,
						target: metricNodeId,
						data: { relationships: [] },
					},
				});

				if (dimensions) {
					this.upsertMetricDimensions({ dimensions });
				}
			}

			for (const connectedMetric of metrics ?? []) {
				const connectedMetricEntry = metricLineageEntries[connectedMetric.name];
				const direction = connectedMetricEntry.direction;
				if (!connectedMetricEntry || direction === 'root') {
					continue;
				}
				const connectedMetricNodeId = this.getMetricNodeId(connectedMetric.name);
				this.upsertMetricToMetricEdge({
					edge: {
						id: `${metricNodeId}-${connectedMetricNodeId}`,
						source: direction === 'upstream' ? connectedMetricNodeId : metricNodeId,
						target: direction === 'upstream' ? metricNodeId : connectedMetricNodeId,
						data: { relationships: connectedMetric.relationships },
					},
				});
			}
		}
	}
}
