import { Flex } from '@chakra-ui/react';
import { ConfirmationModal } from '@components/ConfirmationModal';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import Typography from '../../Typography';
import { FolderData } from './types';

export function DeleteFolderModal({
	isOpen,
	onClose,
	folder,
}: {
	isOpen: boolean;
	onClose: () => void;
	folder: FolderData;
}) {
	const { deleteDashboardFolder, isDeleteFolderLoading } = useDashboardFolders();

	const onDeleteFolder = async () => {
		if (!folder.id) return;
		await deleteDashboardFolder(folder);
		onClose();
	};

	const onCancelDelete = () => {
		onClose();
	};

	const isHavingDashboards = folder?.dashboards && folder?.dashboards?.length > 0;
	return (
		<ConfirmationModal
			primaryButtonLabel={'Delete'}
			isOpen={isOpen}
			modalText={
				<Flex direction={'column'} gap="10px">
					<Typography variant={'DesktopH8Regular'} textAlign="center">
						<Typography color={'gray.1000'} variant={'DesktopH8Bold'}>
							{folder.folderName}
						</Typography>
						&nbsp;
						{isHavingDashboards
							? `and all its dashboards will be permanently deleted.`
							: `will be permanently deleted.`}
					</Typography>
				</Flex>
			}
			modalTitle={'Delete folder.'}
			onSubmit={onDeleteFolder}
			onClose={onCancelDelete}
			isLoading={isDeleteFolderLoading}
			submitColorScheme={'red'}
			minimumModalHeight="300px"
		/>
	);
}
