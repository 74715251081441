import Box from '@components/Box';
import { DatePicker } from '@components/DatePicker';
import { useDatePickerPopover } from '@components/DatePickerPopover/hooks';
import Flex from '@components/Flex';
import Typography from '@components/Typography';

export const DatePickerField = ({
	onDateChange,
	fromDateValue,
	label = 'From',
}: {
	onDateChange: (value: string | null) => void;
	fromDateValue: Date | null;
	label?: string;
}) => {
	const { parentElem } = useDatePickerPopover();

	return (
		<Box height={'318px'}>
			<Flex alignItems={'center'} mb={'12px'}>
				<Typography variant={'DesktopH8Regular'} mr={'12px'}>
					{label}
				</Typography>
				{!!parentElem && (
					<DatePicker
						onChange={onDateChange}
						value={fromDateValue || undefined}
						isPanelAlwaysVisible={true}
						showToday={false}
						getPopupContainer={() => parentElem}
					/>
				)}
			</Flex>
		</Box>
	);
};
