import Flex from '@components/Flex';
import { ArrModelSidePanel } from '@pages/ArrModelPage/components/ArrModelSidePanel';
import classes from '@pages/DashboardPage/DashboardPage.module.scss';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';

export default function ArrModelPageGuard() {
	const isArrModelPageEnabled = useFeatureFlag('pulse.sightfull2.arrModel.enable');
	if (!isArrModelPageEnabled) return null;
	return <ArrModelPage />;
}

function ArrModelPage() {
	return (
		<Flex direction={'row'} height={'100%'}>
			<ArrModelSidePanel />
			<Flex
				className={classes.pageWrapper}
				padding={'0px 356px'}
				flexDirection={'column'}
				alignItems={'flex-start'}
				flex={'1 0 0'}
				alignSelf={'stretch'}
			>
				ABCDEF
			</Flex>
		</Flex>
	);
}
