import { format } from 'date-fns';
import { isString } from 'lodash';
import DatePickerPopover from 'src/common/components/DatePickerPopover';
import { DATE_PRETTY_FORMAT } from 'src/common/utils/consts';
import { toIsoDateWithoutTimezone } from 'src/normalize/utils';
import { EditableValueContainer } from '../../Editors/EditableValueContainer';
import { StringValueDisplay } from '../../Editors/StringValueDisplay';
import { getOperatorDisplayName } from '../constants';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterDateSingleEditor({
	values,
	onApply,
	prefixComponent,
	isEditing,
	onOpenEditor,
	onCloseEditor,
	filter,
}: ValueEditorProps) {
	const displayValue = values[0] ? format(new Date(values[0].toString()), DATE_PRETTY_FORMAT) : '';
	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<DatePickerPopover
				isOpen={isEditing}
				fromDate={(values[0] && isString(values[0]) && new Date(values[0])) || null}
				onCancel={onCloseEditor}
				isPeriodPicker={false}
				onApply={({ fromDate }: { fromDate: Date | null }) => {
					if (fromDate) onApply([toIsoDateWithoutTimezone(fromDate)]);
					onCloseEditor();
				}}
				label={getOperatorDisplayName(filter.operator, filter.type)}
				triggerElement={
					<StringValueDisplay
						tooltip={displayValue}
						value={displayValue}
						hint={'Choose value'}
						onStartEditing={onOpenEditor}
						isEditing={isEditing}
						isEnabled={true}
						isIntercomDetectable={true}
					/>
				}
			/>
		</EditableValueContainer>
	);
}
