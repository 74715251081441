import { useEffect, useState } from 'react';

export const useDatePickerPopover = () => {
	const [parentElem, setParentElement] = useState(document.getElementById('picker-container'));

	useEffect(() => {
		const parentElem = document.getElementById('picker-container');

		if (parentElem) {
			setParentElement(parentElem);
		}
	}, []);

	return {
		parentElem,
	};
};
