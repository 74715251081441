import { LeftSidePanelV2 } from '@components/LeftSidePanelV2';
import Flex from '@components/Flex';
import Button from '@components/Button';
import colors from 'src/style/colors';
import { AppPanel, ChevronLeftLarge16, SightfullLogo } from '@icons/index';
import Box from '@components/Box';
import React from 'react';
import classes from '@components/TopNavigation/TopNavigation.module.scss';
import Link from '@components/Link';
import useNavigation from '@services/useNavigation';
import Typography from '@components/Typography';

export function ArrModelSidePanel() {
	const { navigate, getHref } = useNavigation();

	return (
		<LeftSidePanelV2
			renderItem={(handleCollapsePanelClick, isCollapsed) => {
				return (
					<Flex flexDirection={'column'} alignItems={'flex-start'} gap={'16px'} flex={'1 1 0'} alignSelf={'stretch'}>
						<Flex flexDirection={'column'} alignItems={'flex-start'} alignSelf={'stretch'}>
							<Flex height={'32px'} justifyContent={'space-between'} alignItems={'center'} alignSelf={'stretch'}>
								<Link href={getHref({ path: '' })}>
									<Box
										onClick={(e: React.MouseEvent) => {
											e.preventDefault();
											navigate({
												path: '/',
											});
										}}
									>
										<SightfullLogo className={classes.bigLogo} />
									</Box>
								</Link>
								<Button
									size={'small'}
									variant={'outline'}
									onClick={handleCollapsePanelClick}
									isIconOnly={true}
									colorScheme="gray"
									bgColor={!isCollapsed ? colors.selectedPanelItemColor : 'transparent'}
									_hover={{ bgColor: colors.buttonPanelItemColor }}
								>
									<AppPanel color={isCollapsed ? colors.gray['900'] : colors.gray['1000']} />
								</Button>
							</Flex>
							<Flex
								paddingTop={'16px'}
								flexDirection={'column'}
								alignItems={'flex-start'}
								gap={'16px'}
								alignSelf={'stretch'}
							>
								<Flex padding={'10px'} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
									<Button size={'xxs'} variant={'outline'} isIconOnly={true}>
										<ChevronLeftLarge16 color={colors.gray['800']} />
									</Button>
								</Flex>
								<Flex flexDirection={'column'} alignItems={'flex-start'} gap={'24px'} alignSelf={'stretch'}>
									<Flex flexDirection={'column'} alignItems={'flex-start'} gap={'12px'} alignSelf={'stretch'}>
										<Flex flexDirection={'column'} alignItems={'flex-start'} gap={'8px'}>
											<Flex width={'292px'} height={'24px'} justifyContent={'start'} alignItems={'start'} gap={'10px'}>
												<Typography variant={'DesktopH6Medium'} color={colors.natural['1000']}>
													ARR Model
												</Typography>
											</Flex>
											<Typography variant={'Paragraph14R'} color={colors.natural['800']}>
												Your ARR model will unlock advanced metrics, visualization and investigation capabilities.
											</Typography>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				);
			}}
		/>
	);
}
