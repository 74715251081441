import { Atom, atom } from 'jotai';
import { normalizeOntologyYaml } from '../utils/normalizeYaml';
import { OntologyEditorRawLoadedState, OntologyEditorRawState, OntologyEditorState } from '../utils/types';
import { PropertyUpdate, upsertOntologyProperty } from '../utils/updateYaml';

export const OntologyStateAtom = atom<OntologyEditorRawState>({ loading: true });
export const OntologyStateAtomDerived: Atom<OntologyEditorState> = atom((get) => {
	const state = get(OntologyStateAtom);
	if (state.loading) return state;

	const { savedYaml, editorYaml, hasYamlErrors: hasErrors } = state;
	const hasUnsavedChanges = editorYaml !== savedYaml;
	const canSubmit = hasUnsavedChanges && editorYaml.trim() !== '' && !hasErrors;

	const parsedYaml = normalizeOntologyYaml(editorYaml);
	const saveRunNormalizationValidationRunning =
		state.saveInProgress || state.normalizationInProgress || state.validationInProgress;
	const hasStaleVersion =
		state.savedEntityVersion != undefined &&
		!saveRunNormalizationValidationRunning &&
		state.currentEntityVersion !== state.savedEntityVersion;
	const derivedState: OntologyEditorState = {
		...state,
		hasUnsavedChanges,
		canSubmit,
		parsedYaml,
		hasStaleVersion,
		saveRunNormalizationValidationRunning,
	};
	return derivedState;
});

export const writeFullSuccessOntologyState = atom(
	null,
	(
		_,
		set,
		{
			entityName,
			savedYaml,
			errorMessage,
			editorRequestMessageState,
			savedEntityVersion,
			currentEntityVersion,
		}: Pick<
			OntologyEditorRawLoadedState,
			| 'savedYaml'
			| 'entityName'
			| 'errorMessage'
			| 'editorRequestMessageState'
			| 'savedEntityVersion'
			| 'currentEntityVersion'
		>
	) => {
		set(OntologyStateAtom, {
			loading: false,
			hasYamlErrors: false,
			savedYaml: savedYaml,
			editorYaml: savedYaml,
			entityName,
			errorMessage,
			editorRequestMessageState,
			savedEntityVersion,
			currentEntityVersion,
			saveInProgress: false,
			normalizationInProgress: false,
			validationInProgress: false,
		});
	}
);

export const writeFullErrorOntologyState = atom(
	null,
	(_, set, { errorMessage }: Pick<OntologyEditorRawLoadedState, 'errorMessage'>) => {
		set(OntologyStateAtom, {
			entityName: 'ERROR',
			editorRequestMessageState: 'ERROR',
			loading: false,
			hasYamlErrors: false,
			savedYaml: 'Please refresh, an error occurred.',
			editorYaml: 'Please refresh, an error occurred.',
			errorMessage,
			savedEntityVersion: '',
			currentEntityVersion: '',
			saveInProgress: false,
			normalizationInProgress: false,
			validationInProgress: false,
		});
	}
);

export const writePartialOntologyState = atom(null, (get, set, update: Partial<OntologyEditorRawLoadedState>) => {
	const state = get(OntologyStateAtom);
	if (state.loading) return;
	set(OntologyStateAtom, { ...state, ...update });
});

export const upsertPropertyOntologyState = atom(null, (get, set, update: PropertyUpdate) => {
	const state = get(OntologyStateAtom);
	if (state.loading) return;

	const { editorYaml } = state;

	const result = upsertOntologyProperty({ yaml: editorYaml, ...update });
	set(OntologyStateAtom, {
		...state,
		editorYaml: result.yaml,
	});
	update.result?.(result);
});
