import Divider from '@components/Divider';
import { SpaceSelector } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpaceSelector';
import { SpacesListDrawer } from '@components/LeftSidePanelV2/SpacesNavigationDrawer/SpacesListDrawer';

export const SpacesNavigationDrawer = () => {
	return (
		<>
			<SpaceSelector />
			<Divider marginY="12px" color="natural.200" direction="horizontal" />
			<SpacesListDrawer />
		</>
	);
};
