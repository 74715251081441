import { ApolloError } from '@apollo/client';
import { Box, Center, Flex } from '@chakra-ui/react';
import { LeftSidePanelV2 } from '@components/LeftSidePanelV2';
import classes from '@pages/DashboardPage/DashboardPage.module.scss';
import { CollectionEmptyPage } from 'src/common/components/CollectionEmptyPage';
import DashboardWorkspacePanel from 'src/common/components/DashboardWorkspacePanel';
import Spinner from 'src/common/components/Spinner';
import { useGetWorkspacesSubscription } from 'src/common/hooks/fetching/useWorkspaces';
import useSearchParams from 'src/common/hooks/navigation/useSearchParams';
import { WorkspaceGetSingleQuery } from 'src/generated/graphql';
import Page from 'src/layout/Page';
import Header from 'src/pages/WorkspacePage/Header';
import { useWorkspacePageSearchParams } from 'src/pages/WorkspacePage/hooks/useWorkspacePageSearchParams';
import { FeedSignal } from 'src/types/spaces';
import { NotFoundPage } from '../DashboardPage/NotFoundPage';
import ResponsiveHeader from '../Spaces/common';
import { WorkspaceLayout } from './WorkspaceLayout';

export function WorkspacePage() {
	const { workspaceId } = useWorkspacePageSearchParams();
	const [workspaceQueryData, workspaceQueryError, isWorkspacesQueryLoading] = useGetWorkspacesSubscription({
		id: workspaceId,
	});

	return (
		<Page page="Workspace">
			<Flex direction={'row'} height={'100%'}>
				<LeftSidePanelV2
					renderItem={(handleCollapsePanelClick, isCollapsed) => (
						<DashboardWorkspacePanel isCollapsed={isCollapsed} handleCollapsePanelClick={handleCollapsePanelClick} />
					)}
				/>

				<Box flex={1} className={classes.pageWrapper}>
					<WorkspacePageContent
						workspaceQueryError={workspaceQueryError}
						workspaceQueryData={workspaceQueryData}
						isWorkspacesQueryLoading={isWorkspacesQueryLoading}
					/>
				</Box>
			</Flex>
		</Page>
	);
}

export function WorkspacePageContent({
	workspaceQueryData,
	workspaceQueryError,
	isWorkspacesQueryLoading,
}: {
	workspaceQueryData?: WorkspaceGetSingleQuery;
	workspaceQueryError?: ApolloError;
	isWorkspacesQueryLoading?: boolean;
}) {
	const workspaces = workspaceQueryData?.workspaces;
	const [searchParams] = useSearchParams();
	const linkedId = searchParams.get('signalId') || '';

	if (isWorkspacesQueryLoading)
		return (
			<Center height={'100%'}>
				<Spinner />
			</Center>
		);

	if (workspaceQueryError) return <NotFoundPage title="We couldn't find the workspace." />;

	if (!workspaces?.length) return <CollectionEmptyPage collectionType="workspace" />;

	const [workspace] = workspaces;

	const feedSignals = (workspace.feed?.feed_signals ?? []) as FeedSignal[];

	return (
		<Flex direction="column" width="100%" height={'100%'}>
			<ResponsiveHeader renderItem={(isTiny: boolean) => <Header isTiny={isTiny} workspace={workspace} />} />
			<Box flex={1} data-intercom-area={'workspace'} data-intercom-type={'main'} data-intercom-target={'main'}>
				<WorkspaceLayout feedSignals={feedSignals} linkedId={linkedId} />
			</Box>
		</Flex>
	);
}
