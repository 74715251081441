import { ApolloError } from '@apollo/client';
import {
	DashboardGetSingleQuery,
	Feed_Signals_Insert_Input,
	useSignalUpsertMutation,
	WorkspaceGetSingleQuery,
} from 'src/generated/graphql';
import { WidgetType } from 'src/pages/DashboardPage/types/DashboardFeedSignal';
import { DashboardGetSingle } from 'src/queries/dashboards';
import { WorkspaceGetSingle } from 'src/queries/workspaces';
import { Signal } from 'src/types/signal';
import { Sentiment } from 'src/types/spaces';
import { v4 as uuidv4 } from 'uuid';
import useUser from './stores/useUser';

type UpsertSignalProps = {
	collectionToPin: string[];
	collectionToUnPin: string[];
	message?: string;
	title?: string;
	attachmentContentURL?: string;
	signalId?: string;
	sentiment?: Sentiment;
};

export function useUpsertSignal(
	widgetType: WidgetType,
	originSignal?: Signal
): [(props: UpsertSignalProps) => Promise<any>, { mutationError?: ApolloError; isLoading: boolean }] {
	const [upsertSignalMutation, { error: mutationError, loading: isLoading }] = useSignalUpsertMutation();
	const [user] = useUser();

	function upsertSignal({
		collectionToPin,
		collectionToUnPin,
		message = '',
		attachmentContentURL,
		sentiment = 'none',
		title,
		signalId,
	}: UpsertSignalProps): Promise<any> {
		const signalUUID = signalId || uuidv4();
		const feedInsertObjects: Feed_Signals_Insert_Input[] = collectionToPin.map((feedId) => ({
			signal_id: signalUUID,
			feed_id: feedId,
			display_options: {
				sentiment: sentiment,
			},
		}));

		const optimisticAuthor: SignalQueryAuthorResponseType = {
			email: originSignal?.author?.email ?? user.email,
			first_name: originSignal?.author?.first_name ?? user.firstName,
			last_name: originSignal?.author?.last_name ?? user.lastName,
			picture: originSignal?.author?.picture ?? user.picture,
			__typename: 'users',
		};
		const optimisticSignalResponse: SignalQueryResponseType = {
			id: signalUUID,
			attachment: attachmentContentURL ?? '',
			created_at: new Date(),
			edited_at: null,
			title: title ?? null,
			message: message,
			widget_type: widgetType,
			author: optimisticAuthor,
			author_id: originSignal?.author_id ?? user.id,
			insight_type: null,
			comments: [], // TODO: keep comments here
			__typename: 'signals',
			likes_aggregate: {
				aggregate: {
					count: 0,
					__typename: 'signal_likes_aggregate_fields',
				},
				__typename: 'signal_likes_aggregate',
			},
			my_likes: {
				aggregate: {
					count: 0,
					__typename: 'signal_likes_aggregate_fields',
				},
				__typename: 'signal_likes_aggregate',
			},
			feed_signals: [
				{
					feed: {
						workspace: {
							collection_type: 'dashboard',
							id: collectionToPin[0],
							__typename: 'workspaces',
						},

						__typename: 'feeds',
					},
					resolved: false,
					__typename: 'feed_signals',
				},
			],
		};
		const buildOptimisticFeedSignal = (feedId: string): FeedSignalQueryResponseType => {
			return {
				signal_id: signalUUID,
				feed_id: feedId,
				display_options: { sentiment: 'none' },
				signal_width: 1,
				signal_height: 1,
				signal: optimisticSignalResponse,
				order: new Date().getTime(),
				resolved: false,
				reason: null,
				updated_at: new Date().toISOString(),
				__typename: 'feed_signals',
			};
		};
		return upsertSignalMutation({
			variables: {
				signal_id: signalUUID,
				message: message,
				my_id: user.id,
				attachment: attachmentContentURL ?? '',
				feed_delete_ids: collectionToUnPin,
				feed_insert_objects: feedInsertObjects,
				widget_type: widgetType,
				title: title,
			},
			optimisticResponse: {
				insert_signals_one: optimisticSignalResponse,
				delete_feed_signals: {
					returning: [],
					__typename: 'feed_signals_mutation_response',
				},
				insert_feed_signals: {
					returning: feedInsertObjects.map((feedId) => {
						return {
							feed: {
								id: feedId,
								feed_signals: [buildOptimisticFeedSignal(feedId.feed_id)],
								__typename: 'feeds',
							},
							__typename: 'feed_signals',
						} as FeedFeedSignalQueryResponseType;
					}),
					__typename: 'feed_signals_mutation_response',
				},
			},
			update: (cache) => {
				feedInsertObjects.map((feedInsertObject) => {
					const dashboardFromCache = cache.readQuery<DashboardGetSingleQuery>({
						query: DashboardGetSingle,
						variables: {
							id: feedInsertObject.feed_id,
							my_id: user.id,
						},
					});

					if (!dashboardFromCache) return;
					cache.writeQuery<DashboardGetSingleQuery>({
						query: DashboardGetSingle,
						data: {
							dashboards: [
								{
									...dashboardFromCache.dashboards?.[0],
									feed: {
										...dashboardFromCache.dashboards?.[0]?.feed,
										feed_signals: [
											...(dashboardFromCache.dashboards?.[0]?.feed?.feed_signals ?? []),
											buildOptimisticFeedSignal(feedInsertObject.feed_id),
										],
									},
								},
							],
						},
					});
				});
				feedInsertObjects.map((feedInsertObject) => {
					const workspacesFromCache = cache.readQuery<WorkspaceGetSingleQuery>({
						query: WorkspaceGetSingle,
						variables: {
							id: feedInsertObject.feed_id,
							my_id: user.id,
						},
					});

					if (workspacesFromCache)
						cache.writeQuery<WorkspaceGetSingleQuery>({
							query: WorkspaceGetSingle,
							data: {
								workspaces: [
									{
										...workspacesFromCache.workspaces[0],
										feed: {
											...workspacesFromCache.workspaces[0].feed,
											feed_signals: [
												...(workspacesFromCache.workspaces[0].feed?.feed_signals ?? []),
												buildOptimisticFeedSignal(feedInsertObject.feed_id),
											],
										},
									},
								],
							},
						});
				});
			},
		});
	}

	return [upsertSignal, { mutationError, isLoading }];
}

export function useShareSignalApi(
	originalSignal?: Signal
): [(props: UpsertSignalProps) => Promise<any>, { mutationError?: ApolloError; isLoading: boolean }] {
	return useUpsertSignal('signal', originalSignal);
}

export function useShareTextWidgetApi(): [
	(props: UpsertSignalProps) => Promise<any>,
	{ mutationError?: ApolloError; isLoading: boolean }
] {
	return useUpsertSignal('text');
}

export function useShareDividerWidgetApi(): [
	(props: UpsertSignalProps) => Promise<any>,
	{ mutationError?: ApolloError; isLoading: boolean }
] {
	return useUpsertSignal('divider');
}

type FeedFeedSignalQueryResponseType = {
	feed: {
		id: string;
		feed_signals: FeedSignalQueryResponseType[];
		__typename: 'feeds';
	};
	__typename: 'feed_signals';
};
type FeedSignalQueryResponseType = {
	__typename?: 'feed_signals';
	signal_id: any;
	feed_id: any;
	order: any;
	resolved: boolean;
	reason?: any | null;
	signal_height?: number | null;
	signal_width?: number | null;
	display_options?: any | null;
	signal: SignalQueryResponseType;
	updated_at: string | null;
};
type SignalQueryAuthorResponseType = {
	__typename?: 'users';
	email?: string | null;
	first_name?: string | null;
	last_name?: string | null;
	picture?: string | null;
};
type SignalQueryResponseType = {
	__typename?: 'signals';
	attachment?: any | null;
	created_at: any;
	edited_at?: any | null;
	title?: string | null;
	message: string;
	id: any;
	widget_type?: string | null;
	author_id: any;
	author: SignalQueryAuthorResponseType;
	insight_type?: string | null;
	feed_signals: Array<{
		__typename?: 'feed_signals';
		feed: {
			__typename?: 'feeds';
			workspace?: { __typename?: 'workspaces'; collection_type: string; id: string } | null;
		};
		resolved: boolean;
	}>;
	comments: Array<{
		__typename?: 'comments';
		message?: string | null;
		created_at: any;
		edited_at?: any | null;
		id: any;
		author_id: any;
		author: SignalQueryAuthorResponseType;
	}>;
	likes_aggregate: {
		__typename?: 'signal_likes_aggregate';
		aggregate?: { __typename?: 'signal_likes_aggregate_fields'; count: number } | null;
	};
	my_likes: {
		__typename?: 'signal_likes_aggregate';
		aggregate?: { __typename?: 'signal_likes_aggregate_fields'; count: number } | null;
	};
};
