import Button from '@components/Button';
import Flex from '@components/Flex';
import { SpacesNavigationDrawer } from '@components/LeftSidePanelV2/SpacesNavigationDrawer';
import Tooltip from '@components/Tooltip';
import { AppPanel } from '@icons/index';
import colors from 'src/style/colors';
import { CreateNewButton } from './CreateNewButton';
import { isSpacesPage } from 'src/pages/WorkspacePage/utils';

export function DashboardWorkspacePanel({
	handleCollapsePanelClick,
	isCollapsed = false,
}: {
	handleCollapsePanelClick: VoidFunction;
	isCollapsed?: boolean;
}) {
	return (
		<>
			<Flex justifyContent={'space-between'} mb={'8px'}>
				<CreateNewButton />
				<Tooltip
					size="md"
					variant="fluid"
					label={isCollapsed ? 'Expand' : 'Collapse'}
					placement={'right'}
					marginLeft={'6px'}
				>
					<Button
						size={'small'}
						variant={'outline'}
						colorScheme={'gray'}
						onClick={handleCollapsePanelClick}
						isIconOnly={true}
						bgColor={!isCollapsed ? colors.buttonPanelItemColor : 'transparent'}
						_hover={{ bgColor: colors.hoverPanelItemColor }}
						mixBlendMode={'multiply'}
					>
						<AppPanel color={isCollapsed ? colors.gray['900'] : colors.gray['1000']} />
					</Button>
				</Tooltip>
			</Flex>

			<Flex flexDirection={'column'} flex={1} overflowY={'auto'}>
				{isSpacesPage() && <SpacesNavigationDrawer />}
			</Flex>
		</>
	);
}
