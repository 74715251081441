import {
	formatDimensionLabel,
	formatDimensionLabelV2,
	formatMetricFilterLabel,
	removeLastFilterLabelItem,
	replaceLastCommaWithOr,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { FilterV2, FilterV2Value } from '@pages/MetricPage/utils/state.types';
import { useCallback, useMemo } from 'react';
import {
	useSemanticDefinitions,
	useSemanticDefinitionsForEntity,
} from 'src/common/hooks/stores/useSemanticDefinitions';
import { useModal } from 'src/common/hooks/ui/useModal';
import { isDimensionContext } from 'src/lib/completions/completionProvider';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getContextCompletionFilterTooltip } from 'src/lib/completions/widgetBuilder/contextFilterTooltip';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { useMetricDerivedState } from 'src/pages/MetricPage/hooks/useMetricDerivedState';
import { resolveContextFromSemanticsAndName } from 'src/pages/OntologyPage/components/JoinKeys/utils';
import { useReportEvent } from 'src/services/analytics';
import { DrilldownCard } from '../DrillDownCard';
import { ValueEditorByFilter } from './Editors/ValueEditorByFilter';
import { OperatorSelect } from './Operator';
import { getOperatorDisplayName, supportedOperatorsByFilterType, typeIcon } from './constants';
import { shouldResetFilterValues, useMetricFilter2 } from './useMetricFilter2';

export const FilterDrilldownCard2 = ({
	filter,
	onRemove,
	isEditorAutoOpened,
	eventMetaData,
	onFilterCompletenessChange,
}: {
	filter: FilterV2;
	onRemove?: VoidFunction;
	isEditorAutoOpened?: boolean;
	eventMetaData: object;
	onFilterCompletenessChange?: (isComplete: boolean) => void;
}) => {
	const { reportEvent } = useReportEvent();
	const {
		isOpen: isEditing,
		onClose: onCloseEditor,
		onOpen: onOpenEditor,
	} = useModal({
		defaultIsOpen: isEditorAutoOpened,
	});
	const { isOpen: isOperatorSelectOpen, onClose: onCloseOperatorSelect, onOpen: onOpenOperatorSelect } = useModal();

	const { objectsTypes } = useMetricDerivedState();

	const { isLoadingDimensionValues, options, updateFilter, dimensionsEntity, dimensionName, filterState } =
		useMetricFilter2({
			filter,
		});
	const { values, label, operator, type, key } = filterState;

	const formattedLabel = formatDimensionLabelV2(label);

	const { semanticDefinitions } = useSemanticDefinitions();

	const extendedEventMetaData = {
		...eventMetaData,
		dimension: key,
		dimensionType: type,
		operator: getOperatorDisplayName(operator, filter.type),
	};

	const texts = useMemo(() => {
		const firstCommaSeparatedValuesLabels = values
			?.map((v) => formatMetricFilterLabel(v?.toString() ?? null))
			?.filter((v) => v !== '')
			?.map(formatMetricFilterLabel)
			.join(', ');
		const displayValue = values && replaceLastCommaWithOr(firstCommaSeparatedValuesLabels);

		return {
			title: formattedLabel,
			displayValue,
		};
	}, [values, formattedLabel]);

	const { title, displayValue } = texts;

	const icon = useMemo(() => {
		return typeIcon[type];
	}, [type]);

	const onApply = useCallback(
		(values: FilterV2Value[]) => {
			onFilterCompletenessChange?.(updateFilter(values, operator));
		},
		[onFilterCompletenessChange, operator, updateFilter]
	);

	const metricEntityName =
		useSemanticDefinitionsForEntity(objectsTypes[0])?.meta?.display_name ||
		removeUnderscoresAndCapitalize(objectsTypes[0] || '');

	return (
		<DrilldownCard
			icon={icon}
			onRemove={onRemove}
			removeTooltip={'Remove filter'}
			title={title}
			eventMetaData={extendedEventMetaData}
			valueEditor={
				<ValueEditorByFilter
					filter={filterState}
					options={options}
					displayValue={displayValue}
					values={values}
					onApply={onApply}
					prefixComponent={
						<OperatorSelect
							operator={operator}
							availableOperators={supportedOperatorsByFilterType[type]}
							type={type}
							onSelectOperator={(operator) => {
								const values = shouldResetFilterValues(filter.operator, operator) ? [] : filter.values;
								onFilterCompletenessChange?.(updateFilter(values, operator));
								onOpenEditor();
							}}
							dimension={filter.key}
							isOpen={isOperatorSelectOpen}
							onClose={onCloseOperatorSelect}
							onOpen={onOpenOperatorSelect}
						/>
					}
					isLoadingDimensionValues={isLoadingDimensionValues}
					eventMetaData={extendedEventMetaData}
					isEditing={isEditing}
					onOpenEditor={() => {
						reportEvent({
							event: 'filters-edit-values',
							metaData: {
								dimension: filter.key,
								values: filter.values,
								operator: getOperatorDisplayName(operator, filter.type),
								'dimension type': filter.type,
								application: 'metric investigation',
								feature: 'filters',
							},
						});
						onCloseOperatorSelect();
						onOpenEditor();
					}}
					onCloseEditor={onCloseEditor}
					isEditorAutoOpened={true}
				/>
			}
			isEditing={isEditing}
			titleHoverTooltipBuilder={() => {
				if (!dimensionName || !semanticDefinitions || !dimensionsEntity) return;
				const context = resolveContextFromSemanticsAndName(semanticDefinitions, dimensionsEntity, dimensionName);
				const formattedDimensionPath = `${metricEntityName} / ${removeLastFilterLabelItem(
					formatDimensionLabel(label)
				)}`;
				if (!context || !isDimensionContext(context)) return;
				return (
					<MonacoTooltipComponent
						tooltipMarkdownString={getContextCompletionFilterTooltip(context, dimensionName, formattedDimensionPath)}
					/>
				);
			}}
		/>
	);
};
