import { isString } from 'lodash';
import { RelativeDatePickerPopover } from 'src/common/components/RelativeDatePickerPopover/RelativeDatePickerPopover';
import { dateUnitSelectOptions, isDateUnit } from 'src/common/components/RelativeDatePickerPopover/types';
import { EditableValueContainer } from '../../Editors/EditableValueContainer';
import { StringValueDisplay } from '../../Editors/StringValueDisplay';
import { defaultRelativeDateUnit, defaultRelativeDateValue } from '../constants';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterDateRelativeEditor({
	values,
	onApply,
	prefixComponent,
	isEditing,
	onOpenEditor,
	onCloseEditor,
}: ValueEditorProps) {
	const hasValue = values[0] != undefined && values[1] != undefined;
	const dateUnit = isString(values[1]) && isDateUnit(values[1]) ? values[1] : defaultRelativeDateUnit;
	const value = values[0] != undefined ? Number(values[0]) : defaultRelativeDateValue;
	const displayValue = !hasValue ? '' : value + ' ' + dateUnitSelectOptions.find((o) => o.value === dateUnit)?.label;
	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<RelativeDatePickerPopover
				isOpen={isEditing}
				onCancel={onCloseEditor}
				onApply={({ value, unit }) => {
					onApply([value, unit]);
					onCloseEditor();
				}}
				triggerElement={
					<StringValueDisplay
						tooltip={displayValue}
						value={displayValue}
						hint={'Choose value'}
						onStartEditing={onOpenEditor}
						isEditing={isEditing}
						isEnabled={true}
						isIntercomDetectable={true}
					/>
				}
				initialUnit={dateUnit}
				initialValue={value}
			/>
		</EditableValueContainer>
	);
}
