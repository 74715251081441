import Box from '@components/Box';
import { RangePicker } from '@components/DatePicker';
import { useDatePickerPopover } from '@components/DatePickerPopover/hooks';
import Flex from '@components/Flex';
import Typography from '@components/Typography';
import classesCustomPeriodPickerDialog from '@pages/MetricPage/components/PeriodPicker/CustomPeriodPickerDialog/CustomPeriodPickerDialog.module.scss';
import { DATE_PRETTY_FORMAT } from 'src/common/utils/consts';

export const PeriodPickerField = ({
	onDateRangeChange,
	fromDateValue,
	toDateValue,
}: {
	onDateRangeChange: ([fromDate, toDate]: [Date, Date]) => void;
	fromDateValue: Date | null;
	toDateValue: Date | null;
}) => {
	const { parentElem } = useDatePickerPopover();

	return (
		<Box height={'358px'}>
			<Flex>
				<Flex flexDirection={'column'} mr={'12px'} justifyContent={'space-between'} gap={'12px'}>
					<Flex alignItems={'center'} height={'42px'}>
						<Typography variant={'DesktopH8Regular'}>From</Typography>
					</Flex>
					<Flex alignItems={'center'} height={'42px'}>
						<Typography variant={'DesktopH8Regular'}>To</Typography>
					</Flex>
				</Flex>
				{!!parentElem && (
					<RangePicker
						className={classesCustomPeriodPickerDialog.rangePickerInput}
						separator={null}
						suffixIcon={null}
						onChange={onDateRangeChange as any} // We are not using DATA FNS types so it's a must here
						defaultValue={[fromDateValue, toDateValue]}
						format={DATE_PRETTY_FORMAT}
						allowClear={false}
						getPopupContainer={() => parentElem}
						open={true}
						placeholder={['', '']}
					/>
				)}
			</Flex>
		</Box>
	);
};
