import Flex from '@components/Flex';
import Skeleton from 'src/common/components/Skeleton';
import classes from './SkeletonFolder.module.scss';
import _ from 'lodash';
const { skeletonWrapper, folderSkeleton } = classes;

const REPEAT_FOLDERS_NUMBER = 17;

export function SkeletonFolder() {
	return (
		<Flex className={skeletonWrapper}>
			{_.times(REPEAT_FOLDERS_NUMBER, (index) => (
				<Skeleton key={index} className={folderSkeleton} />
			))}
		</Flex>
	);
}
