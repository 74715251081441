import { format } from 'date-fns';
import { DATE_PRETTY_FORMAT } from 'src/common/utils/consts';
import { buildDollarFormatter } from 'src/lib/metricRules/valueFormatters';

export const formatStringToDisplay = (value: any): string => String(value);
export const formatBooleanToDisplay = (value: any): string => (value ? 'True' : 'False');
export const formatDollarToDisplay = buildDollarFormatter(1);
export const formatDateToDisplay = (value: any): string =>
	value && isParsableToDate(value) ? format(new Date(value), DATE_PRETTY_FORMAT) : '';

function isParsableToDate(value: string): boolean {
	const timestamp = Date.parse(value);
	return !isNaN(timestamp);
}
