import { SelectOption } from '../Select/types';

export type RelativeDatePickerPopoverProps = {
	isOpen: boolean;
	onCancel: VoidFunction;
	onApply: ({ unit, value }: { unit: DateUnit; value: number }) => void;
	triggerElement?: React.ReactNode;
	initialUnit: DateUnit;
	initialValue: number;
};
export const dateUnits = ['day', 'week', 'month'] as const;
export type DateUnit = typeof dateUnits[number];
export const dateUnitSelectOptions: SelectOption<DateUnit>[] = [
	{ value: 'day', label: 'Days' },
	{ value: 'week', label: 'Weeks' },
	{ value: 'month', label: 'Months' },
];

export const isDateUnit = (unit: string): unit is DateUnit => {
	return dateUnits.map((unit) => String(unit)).includes(unit);
};
