import {
	GetAllFoldersQuery,
	useDeleteFolderMutation,
	useEditFolderMutation,
	useInsertFolderMutation,
	useReorderDashboardMutation,
	useReorderFolderMutation,
} from 'src/generated/graphql';
import { useReportEvent } from 'src/services/analytics';
import useToast from './ui/useToast';
import { ListAllFolders } from 'src/queries/folders';
import useUser from './stores/useUser';
import { FolderData } from '../components/LeftSidePanelV2/DashboardFolder/types';

export const useDashboardFolders = () => {
	const { reportEvent } = useReportEvent({ feature: 'Sidebar' });

	const [{ id: my_id }] = useUser();
	const toast = useToast();
	const [insertFolder, { loading: isCreateFolderLoading }] = useInsertFolderMutation();
	const [deleteFolder, { loading: isDeleteFolderLoading }] = useDeleteFolderMutation();
	const [editFolder, { loading: isEditFodlerLoading }] = useEditFolderMutation();
	const [reorderFolder] = useReorderFolderMutation();
	const [reorderDashboard] = useReorderDashboardMutation();

	const onError = (err: unknown) => {
		if (err instanceof Error) toast({ variant: 'error', message: err.message });
	};
	const createDashboardFolder = async (name: string, emoji: string, isWithToast?: boolean) => {
		reportEvent({ event: 'sidebar-create-new-item-create', metaData: { itemType: 'folder' } });

		const optimisticId = `temp-${Date.now()}`;
		const optimisticFolder: {
			__typename: 'folders';
			id: string;
			name: string;
			emoji: string;
			order: number;
			dashboards: any[];
		} = {
			__typename: 'folders',
			id: optimisticId,
			name,
			emoji,
			order: Date.now() * 1000,
			dashboards: [],
		};
		try {
			const insertFolderResult = await insertFolder({
				variables: { name, emoji },
				optimisticResponse: {
					insert_folders_one: optimisticFolder,
				},
				update: (cache) => {
					const foldersFromCache = cache.readQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
					});
					if (foldersFromCache) {
						cache.writeQuery<GetAllFoldersQuery>({
							query: ListAllFolders,
							variables: { my_id },
							data: { folders: [optimisticFolder, ...foldersFromCache.folders] },
						});
					}
				},
			});
			if (isWithToast) toast({ variant: 'ok', message: 'Successfully created folder' });
			return insertFolderResult.data;
		} catch (error) {
			onError(error);
		}
	};

	const deleteDashboardFolder = async (folder: FolderData) => {
		reportEvent({
			event: 'sidebar-delete-item',
			metaData: { itemType: 'folder', name: folder.folderName },
		});
		try {
			const insertFolderResult = await deleteFolder({ variables: { id: folder.id } });
			if (insertFolderResult.data) toast({ variant: 'ok', message: 'Successfully deleted folder' });
		} catch (error) {
			onError(error);
		}
	};

	const editDashboardFolder = async (folder: FolderData) => {
		const { id, folderName, emoji } = folder;
		if (!folderName || !emoji) return;
		reportEvent({ event: 'sidebar-edit-item', metaData: { itemType: 'folder', name: folder.folderName } });
		try {
			const insertFolderResult = await editFolder({
				variables: {
					id,
					name: folderName,
					emoji,
				},
				optimisticResponse: {
					update_folders: {
						__typename: 'folders_mutation_response',
						returning: [
							{
								__typename: 'folders',
								id,
								name: folderName,
								emoji,
							},
						],
					},
				},
				update: (cache, { data }) => {
					const updatedFolder = data?.update_folders?.returning[0];
					if (updatedFolder) {
						const foldersFromCache = cache.readQuery<GetAllFoldersQuery>({
							query: ListAllFolders,
							variables: { my_id },
						});
						if (foldersFromCache) {
							const updatedFolders = foldersFromCache.folders.map((folder) => {
								if (folder.id === id) return { ...folder, name: updatedFolder.name, emoji: updatedFolder.emoji };
								return folder;
							});

							cache.writeQuery<GetAllFoldersQuery>({
								query: ListAllFolders,
								variables: { my_id },
								data: { folders: updatedFolders },
							});
						}
					}
				},
			});
			if (insertFolderResult.data) toast({ variant: 'ok', message: 'Successfully updated folder' });
		} catch (error) {
			onError(error);
		}
	};

	const reorderDashboardFolder = async (id: string, new_order: number) => {
		try {
			await reorderFolder({
				variables: {
					id,
					new_order: Math.floor(new_order),
				},
				optimisticResponse: {
					update_folders: {
						__typename: 'folders_mutation_response',
						returning: [
							{
								__typename: 'folders',
								id,
								order: Math.floor(new_order),
							},
						],
					},
				},
				update: (cache) => {
					const foldersFromCache = cache.readQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
					});
					if (foldersFromCache) {
						const updatedFolders = foldersFromCache.folders.map((folder) => {
							if (folder.id === id) return { ...folder, order: Math.floor(new_order) };
							return folder;
						});

						cache.writeQuery<GetAllFoldersQuery>({
							query: ListAllFolders,
							variables: { my_id },
							data: { folders: updatedFolders },
						});
					}
				},
			});
		} catch (error) {
			onError(error);
		}
	};

	const reorderFolderDashboard = async (dashboard_id: string, new_folder_id: string, new_order: number) => {
		try {
			await reorderDashboard({
				variables: {
					dashboard_id,
					new_folder_id,
					new_order: Math.floor(new_order),
				},
				optimisticResponse: {
					update_workspaces: {
						__typename: 'workspaces_mutation_response',
						returning: [
							{
								__typename: 'workspaces',
								id: dashboard_id,
								folder_id: new_folder_id,
								order: Math.floor(new_order),
							},
						],
					},
				},
				update: (cache) => {
					const foldersFromCache = cache.readQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
					});
					if (foldersFromCache) {
						const movedDashboardFolder = foldersFromCache.folders.find((folder) =>
							folder.dashboards.some((dashboard) => dashboard.id === dashboard_id)
						);
						if (!movedDashboardFolder) return;
						const updatedDashboards = movedDashboardFolder.dashboards.map((dashboard) => {
							if (dashboard.id === dashboard_id) return { ...dashboard, order: Math.floor(new_order) };
							return dashboard;
						});

						const updatedFolders = foldersFromCache.folders.map((folder) => {
							if (folder.id === new_folder_id) return { ...folder, dashboards: updatedDashboards };
							return folder;
						});
						cache.writeQuery<GetAllFoldersQuery>({
							query: ListAllFolders,
							variables: { my_id },
							data: { folders: updatedFolders },
						});
					}
				},
			});
		} catch (error) {
			onError(error);
		}
	};

	return {
		createDashboardFolder,
		isCreateFolderLoading,
		deleteDashboardFolder,
		isDeleteFolderLoading,
		editDashboardFolder,
		isEditFodlerLoading,
		reorderDashboardFolder,
		reorderFolderDashboard,
	};
};
