import { Box, ModalHeader } from '@chakra-ui/react';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { CloseLarge16 } from '@components/Icons';
import { FolderSelect } from '@components/FolderSelect';
import Modal from '@components/Modal';
import colors from 'src/style/colors';
import { useState } from 'react';
import { useDashboardFolders } from 'src/common/hooks/useDashboardFolders';
import { DEFAULT_EMOJIPICKER_VALUE } from '../../EmojiPicker/EmojiPicker';
import useMutation from 'src/common/hooks/fetching/useMutation';
import { MoveCollectionToFolder } from 'src/queries/collections';
import useToast from 'src/common/hooks/ui/useToast';
import Divider from '../../Divider';
import useNavigation from 'src/services/useNavigation';
import { useReportEvent } from 'src/services/analytics';
import { DashboardPath } from 'src/pages/DashboardPage';
import { GetAllFoldersQuery, GetCollectionsSubscription } from 'src/generated/graphql';
import { ListAllFolders } from 'src/queries/folders';
import useUser from 'src/common/hooks/stores/useUser';
import { FolderData } from './types';

type ModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	collection?: GetCollectionsSubscription['workspaces'][0];
};

export const initialFolderData = {
	id: '',
	emoji: DEFAULT_EMOJIPICKER_VALUE,
	folderName: '',
};

export const MoveToFolderModal = ({ isOpen, onClose, collection }: ModalProps) => {
	const closeModal = () => {
		setFolderData(initialFolderData);
		onClose();
	};

	const [{ id: my_id }] = useUser();
	const [folderData, setFolderData] = useState<FolderData>(initialFolderData);
	const { navigate } = useNavigation();
	const toast = useToast();
	const { reportEvent } = useReportEvent();

	const { createDashboardFolder, isCreateFolderLoading } = useDashboardFolders();
	const [collectionMutate, { loading: isCollectionLoading }] = useMutation(MoveCollectionToFolder);

	const isLoading = isCreateFolderLoading || isCollectionLoading;
	const isSubmitDisabled = !folderData.id && (!folderData.emoji || !folderData.folderName);

	const onViewNow = () => {
		if (!collection?.id) return;
		const URL = `${DashboardPath}/${collection.id}`;
		navigate({ path: URL });
	};

	const onSubmit = async () => {
		if (folderData.id) {
			reportEvent({
				event: 'sidebar-move-dashboard-move',
				metaData: { field: 'folder', folderName: folderData.folderName, itemType: 'folder' },
			});
			await onSubmitMove();
		} else {
			reportEvent({
				event: 'sidebar-move-dashboard-create-new-folder-and-move',
				metaData: { field: 'folder', fodlerName: folderData.folderName },
			});
			await createDashboardFolder(folderData?.folderName || '', folderData?.emoji || '', false).then((res) =>
				onSubmitMove(res?.insert_folders_one?.id)
			);
		}
		closeModal();
	};

	const onSubmitMove = async (newFolderId?: string) => {
		if (!collection?.id) return;
		const folder_id = newFolderId || folderData.id;
		try {
			await collectionMutate({
				variables: {
					id: collection.id,
					folder_id,
				},
				optimisticResponse: {
					update_workspaces_by_pk: {
						__typename: 'workspaces',
						id: collection.id,
						folder_id,
					},
				},
				update: (cache) => {
					const foldersFromCache = cache.readQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						variables: { my_id },
					});
					if (!foldersFromCache) return;
					const movedDashboardFolder = foldersFromCache.folders.find((folder) =>
						folder.dashboards.some((dashboard) => dashboard.id === collection?.id)
					);
					if (!movedDashboardFolder) return;
					const movedDashboard = movedDashboardFolder.dashboards.find((dashboard) => dashboard.id === collection?.id);
					if (!movedDashboard) return;
					const updatedFolders = foldersFromCache.folders.map((folder) => {
						if (folder.id === folder_id) {
							const updatedDashboards = [...folder.dashboards, { ...movedDashboard }];
							return { ...folder, dashboards: updatedDashboards };
						} else if (folder.id === movedDashboardFolder.id) {
							const updatedDashboards = folder.dashboards.filter((dashboard) => dashboard.id !== collection?.id);
							return { ...folder, dashboards: updatedDashboards };
						}
						return folder;
					});
					cache.writeQuery<GetAllFoldersQuery>({
						query: ListAllFolders,
						data: { folders: updatedFolders },
					});
				},
			})
				.then(() => {
					toast({
						variant: 'ok',
						message: (
							<Flex>
								<Typography textAlign={'center'} variant="Paragraph14R" color="gray.1000">
									Successfully moved to&nbsp;
								</Typography>
								&nbsp;
								<Typography textAlign={'center'} variant="Paragraph14SB" color="gray.1000">
									{folderData.folderName}
								</Typography>
								<Box>
									<Divider margin={'0 12px'} width="1px" direction={'vertical'} />
								</Box>
								<Typography
									onClick={onViewNow}
									cursor={'pointer'}
									textDecoration={'underline'}
									textAlign={'center'}
									variant="Paragraph14M"
									color="gray.1000"
								>
									View now
								</Typography>
							</Flex>
						),
						duration: 5000,
					});
				})
				.finally(closeModal);
		} catch (error) {
			if (error instanceof Error) toast({ variant: 'error', message: error.message });
		}
	};

	return (
		<Modal maxWidth="460px" isOpen={isOpen} onClose={closeModal} closeOnOverlayClick={false} isCentered>
			<ModalHeader boxShadow={'borderBottom'} p="16px">
				<Flex textAlign={'center'} justifyContent="space-between" alignItems="center">
					<Button isIconOnly variant="outline" onClick={closeModal} size="inline" colorScheme="black">
						<CloseLarge16 color={colors.gray[900]} />
					</Button>
					<Box margin={'0 auto'}>
						<Typography color={'gray.1000'} marginLeft={'-32px'} variant="DesktopH7Medium">
							Move to...
						</Typography>
					</Box>
				</Flex>
			</ModalHeader>
			<Flex width={'100%'} gap={'24px'} flexDirection={'column'} boxShadow={'borderBottom'} padding={'24px'}>
				<FolderSelect flow="move" isInitSelect folderData={folderData} setFolderData={setFolderData} />
			</Flex>
			<Flex py="16px" px="24px" justifyContent={'space-between'}>
				<Button color={'gray.1000'} size={'medium'} colorScheme="gray" variant="outline" onClick={closeModal}>
					Cancel
				</Button>
				<Button
					isLoading={isLoading}
					isDisabled={isSubmitDisabled}
					size={'medium'}
					variant="solid"
					colorScheme={isSubmitDisabled ? 'gray' : 'blue'}
					onClick={onSubmit}
				>
					Move
				</Button>
			</Flex>
		</Modal>
	);
};
